import React, { Fragment, useEffect, useState } from 'react'
import Container from 'typedi'

import { API } from '../../api/API'

import { UserType } from '../../api/UserType'
import { WalletType } from '../../api/WalletType'
import { LoadContractsHook } from '../../hooks/LoadContractsHook'

import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { UserDataActions } from '../../redux/slices/UserData'
import { ContractsInstance } from '../../utilities/getContractInstance'

import { BN } from '../../utilities/BN'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { RPC_URL, chainId } from '../../config'
import Web3 from 'web3'
import { setWeb3 } from '../../utilities/getWeb3'
import { toast } from 'react-toastify'
import WalletConnect from '../../assets/WalletConnect'
class ExternalWalletState {
	static state: { address: string } = { address: '' }
}

export const WithdrawRequests: React.FC = () => {
	const [users, setUsers] = useState<Array<UserType & WalletType>>([])
	const { load } = LoadContractsHook()
	const update = useAppSelector((state) => state.userDataReducer.update)
	const primary = useAppSelector((state) => state.userDataReducer.primary)
	const contracts_loaded = useAppSelector((state) => state.userDataReducer.contracts_loaded)

	const [connected, setIsConnected] = useState<boolean>(false)

	useEffect(() => {
		return () => {
			setWeb3()
			load()
		}
	}, [])

	const toggleConnect = () => {
		if (!connected) walletConnect()
		else walletDisconnect()
	}
	const walletConnect = async () => {
		try {
			const provider = new WalletConnectProvider({
				rpc: {
					[chainId]: RPC_URL as string,
				},
				chainId: parseInt(chainId),
			})
			console.log(provider)
			// Show QR Code Modal
			if (!provider.connected) {
				await provider.enable()
			}
			// inject WalletConnect provider and reload contracts
			setWeb3(provider)
			load()
			// Create Web3 instance
			const web3 = new Web3(provider as any)
			const walletAccount = await web3.eth.getAccounts()
			ExternalWalletState.state.address = walletAccount[0]
			setIsConnected(true)
		} catch (err: any) {
			console.log(err)
			toast.error(err.message || 'Error connecting to wallet')
		}
	}

	const walletDisconnect = async () => {
		const provider = new WalletConnectProvider({
			rpc: {
				[chainId]: RPC_URL as string,
			},
		})
		await provider.disconnect()
		setIsConnected(false)
	}

	useEffect(() => {
		if (contracts_loaded) {
			const run = async () => {
				const users = (await API.get_all())
					.map<UserType & WalletType>((user) => ({
						...user.wallet,
						...user,
						/* Hardcoded User Value as Wallet also has uid*/
						uid: user.uid,
					}))
					.filter((user) => user.unApprovedWithdrawableBalance > 0)

				// console.log({ users })
				setUsers(users)
			}
			run()
		} else {
			load()
		}
	}, [update, contracts_loaded, load])

	const keys: (keyof (UserType & WalletType) | 'approve')[] = [
		'email',
		'name',
		'totalBalance',
		'reservedBalance',
		'withdrawableBalance',
		'unApprovedWithdrawableBalance',
		'approve',
	]

	// const handleApproval = async (bet_uid: string, approve: boolean) => {
	// 	console.log(await H2H.approve({ uid: bet_uid, approve }))
	// 	setUpdate((update) => update + 1)
	// }
	return (
		<Fragment>
			{
				<>
					<div className='walletConnectContainer'>
						Withdraw Requests
						<button className='walletConnectBtn' onClick={toggleConnect}>
							<WalletConnect />
							{connected ? 'Disconnect' : 'Connect'}
						</button>
					</div>

					<table>
						<tr>
							{keys.map((heading) => {
								return (
									<Fragment>
										<th style={{ padding: '10px', textTransform: 'capitalize' }}>{heading}</th>
									</Fragment>
								)
							})}
						</tr>
						{users.map((user) => {
							return (
								<Fragment>
									<tr>
										{keys.map((heading) => {
											return contracts_loaded ? UserList(user, heading, primary) : <Fragment></Fragment>
										})}
									</tr>
								</Fragment>
							)
						})}
					</table>
				</>
			}
		</Fragment>
	)
}

const contracts = Container.get(ContractsInstance)

function UserList(user: UserType & WalletType, heading: 'approve' | keyof (UserType & WalletType), primary: string): JSX.Element {
	const dispatch = useAppDispatch()
	// console.log({ heading })

	if (heading === 'approve') {
		return (
			<Fragment>
				<td className='btn'>
					<button
						onClick={async () => {
							try {
								const address = ExternalWalletState.state.address
								if (address === '') {
									return toast.error('Please connect through WalletConnect...')
								}
								toast.info('Approving, check your provider for txn...', {
									toastId: 'withdraw-approval',
									autoClose: false,
								})
								const treasury = contracts.get_treasury()
								// console.log({ eth: user.ethereum_address })
								const allowanceInWei = await treasury.methods.allowance(user.ethereum_address).call()
								const allowance = Web3.utils.fromWei(allowanceInWei)
								const approvalNeeded = Web3.utils.fromWei(user.unApprovedWithdrawableBalance.toString())
								if (allowance < approvalNeeded) {
									const approveCaller = treasury.methods.approve(
										user.ethereum_address,
										user.unApprovedWithdrawableBalance.toString()
									)
									const gasRes = await approveCaller.estimateGas({ from: address })
									await approveCaller
										.send({ from: address, gas: gasRes })
										.on('transactionHash', (transactionHash: any) => {
											if (transactionHash) {
												// console.log(transactionHash)
												// await API.approve_withdrawal(user.uid)
												// dispatch(UserDataActions.setUpdate())
												// toast.dismiss('withdraw-approval')
												// toast.success('Approved!')
											}
										})
										.on('confirmation', (confNumber: number, receipt: any, latestBlockHash?: string) => {
											// console.log('Confirmed', confNumber, receipt, latestBlockHash)
										})
										.catch((err: any) => {
											toast.dismiss('withdraw-approval')
											console.log('Catch:', err?.message)
										})
								} else {
									dispatch(UserDataActions.setUpdate())
									await API.approve_withdrawal(user.uid)
									toast.dismiss('withdraw-approval')
									toast.success('Approved!')
								}
							} catch (error: any) {
								console.log(error.message)
								toast.dismiss('withdraw-approval')
								toast.error(error?.message || 'Something went wrong')
							}
						}}
						style={{ outline: 'none', border: 0 }}
						className='bttn'
					>
						Approve
					</button>
				</td>
			</Fragment>
		)
	} else if (
		heading === 'totalBalance' ||
		heading === 'reservedBalance' ||
		heading === 'withdrawableBalance' ||
		heading === 'unApprovedWithdrawableBalance'
	) {
		return (
			<Fragment>
				<td>{new BN(user[heading]).dividedBy('1e18').toFormat(2)}</td>
			</Fragment>
		)
	} else
		return (
			<Fragment>
				<td>{user[heading]}</td>
			</Fragment>
		)
}
