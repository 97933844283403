import React from 'react'
import './Landing.scss'
import { LogoNav } from '../../components/LogoNav/LogoNav'
import {
	landingImageOne,
	processFirst,
	processSec,
	processThird,
	devicesImage,
	allInOne,
	multiplePlatforms,
	responsive,
	scalability,
	userExperience,
	security,
} from '../../assets/index'
import { Questions } from '../../components/Question/Question'
import { useHistory } from 'react-router'
import Player from './Player'

export const Landing: React.FC = () => {
	const [currsection, setCurrsection] = React.useState<1 | 2 | 3 | 4>(1)
	const [open, setOpen] = React.useState(false)
	const history = useHistory()
	const getQuestions = React.useCallback((value: 1 | 2 | 3 | 4) => {
		switch (value) {
			case 1:
				return (
					<div id='sectionOne'>
						<Questions name='Why Vanilla Bet?' key={Math.random()}>
							The betting industry is rapidly expanding, however, a lot of its potential is limited by outrageous rake fees and outdated
							tools. Vanilla Bet solves this by providing an updated, top-of-the-line, and secure betting platform for anyone that wants
							to gamble.
						</Questions>
						<Questions name='The Head-to-Head dApp, how will it work?' key={Math.random()}>
							All you have to do is create an event for a bet to be placed on and verify the start of the event. Later, we will provide
							a fixture list for premium sports events which will expand as the platform develops. Users therefore, can place their bets
							on unique outcomes and events. You can bet on any sport, any game, any real life win or lose scenario, the possibilities
							are infinite. An oracle data pull will create automated results where applicable for known events.
						</Questions>
						<Questions name='How does the bet go live?' key={Math.random()}>
							Once a bet has been created, other users on the network will match your bet using their VNLA tokens, the currency of
							Vanilla Bet. The winners of the bet will be able to double their stake and the losers will lose their tokens. The Vanilla
							Betting platform is simple, transparent, and limitless.
						</Questions>
						<Questions name='What about pool betting?' key={Math.random()}>
							The Vanilla Betting platform also allows users to participate in Pool Betting scenarios for key sporting events. Users can
							place their bets on their preferred outcome. After the betting's are closed, the odds are generated depending on the level
							of bets placed on each outcome. There's no brokerage fee, even for pool betting.
						</Questions>
					</div>
				)
			case 2:
				return (
					<div id='sectionTwo'>
						<Questions name='What do I need to get started?' key={Math.random()}>
							You'll need the VNLA token. It's the currency used inside the Vanilla Bet platform. You can find more information here at
							our official website: https://www.vanilla.network/
						</Questions>
						<Questions name='Why does Vanilla Bet need to connect to my wallet?' key={Math.random()}>
							This is how the platform is able to deposit and withdraw VNLA tokens onto the platform which you can then use to place
							bets!
						</Questions>
						<Questions name='Are there any fees involved when using Vanilla Bet?' key={Math.random()}>
							The only deduction you'll incur is the initial 5% burn when depositing/withdrawing your VNLA tokens to/from the Vanilla
							Bet platform to your wallet - this is inbuilt into the VNLA token. When on the platform, you can bet any number of times
							without incurring any fees.
						</Questions>
						<Questions name='Why do I need to create an account?' key={Math.random()}>
							When you register for a Vanilla Bet account, we'll link your wallet to your newly created account. This way your VNLA is
							secure and only you have access to it. It also allows us to give you quick and easy support if you ever need it.
						</Questions>
					</div>
				)
			case 3:
				return (
					<div id='sectionThree'>
						<Questions name='How do I deposit VNLA into my Vanilla Bet account?' key={Math.random()}>
							<ol>
								<li>Once logged into your Vanilla Bet account, click on the "DEPOSIT" link in the top right hand corner.</li>
								<li>You'll be presented with a lightbox that has a few options. Type in the amount of VNLA to deposit.</li>
								<li>Once VNLA token amount entered, click "Deposit". Follow the prompts from your connected wallet.</li>
								<li>After transactions are confirmed (may take a minute or two) you should see your new VNLA balance reflected.</li>
							</ol>
							Note: VNLA has in built 5% burn - to learn more about tokenomics please visit: https://www.vanilla.network/
						</Questions>
						<Questions name='How do I withdraw VNLA from my Vanilla Bet account?' key={Math.random()}>
							<ol>
								<li>Once logged into your Vanilla Bet account, click on the "DEPOSIT" link in the top right hand corner.</li>
								<li>
									You'll be presented with a lightbox that has a few options. Type in the amount of VNLA via the "Withdraw VNLA"
									section.
								</li>
								<li>Once VNLA token amount entered, click "Withdraw". Follow the prompts from your connected wallet.</li>
								<li>Your withdraw request will be sent. Please allow 24 hours to approve withdraw.</li>
							</ol>
							Note: VNLA has in built 5% burn - to learn more about tokenomics please visit: https://www.vanilla.network/
						</Questions>
						<Questions name='How long do withdrawals take to process?' key={Math.random()}>
							Your withdraw request will be sent. Please allow 24 hours to approve withdraw.
						</Questions>
						<Questions name='Are there any fees after VNLA tokens have been deposited onto Vanilla Bet?' key={Math.random()}>
							There are zero fees/rake once your VNLA tokens have been deposited onto the platform. This has all been made possible by
							partnering with the Polygon Network. Essentially the gas fees will be sponsored by the administrators, so the end user
							incurs no fee to place a bet on the Vanilla Bet platform.
						</Questions>
					</div>
				)
			case 4:
				return (
					<div id='sectionFour'>
						<Questions name='Support'>
							The Vanilla Bet platform has a dedicated service desk for all technical queries. For any issues experienced, please email
							our support team: <a href='mailto:support@vanillabet.io'>support@vanillabet.io</a> We aim to respond within 24 hours of
							your query.
						</Questions>
					</div>
				)
			default:
				return
		}
	}, [])
	// console.log(currsection)
	const toggleOpen = () => setOpen((o) => !o)
	return (
		<>
			<div className='LANDING'>
				<div className='landingTopSection'>
					<LogoNav />
					<div className='landingTopDataContainer'>
						<div id='textContainer'>
							<p>
								Head-to-Head bets allow for users to bet on almost
								<br />
								anything with a 50/50 outcome.
							</p>
							<p id='boldData'>
								DECENTRALIZED
								<span> HEAD-TO-HEAD </span>
								<span>BETTING</span>
								<span>(PUBLIC BETA)</span>
							</p>
							<p>
								Powered by Vanilla, the betting platform allows for holders of the VNLA token to participate in Head-to-Head bets
								against community members and also participate in pool betting scenarios.
							</p>
							<button onClick={() => history.push('/auth/register')}>GET STARTED</button>
							<button style={{marginTop: '10px'}} onClick={() => window.open('/betting-guide.pdf', '_blank')}>GUIDE</button>
						</div>
						<img src={landingImageOne} alt='landingone-img' style={{ width: 'inherit' }} />
					</div>
				</div>
				<div className='landingCenterSection'>
					<div className='workdemoContainer'>
						<div className='processesHeading'>
							<p>GET STARTED IN 3 EASY STEPS</p>
						</div>
						<div id='imageContainer'>
							<div className='imageSection'>
								<div className='data'>
									<img src={processFirst} alt='first-process' />
									<p className='heading'>Create Your Bet</p>
									<p>Using our super easy and secure betting platform, create your bet. Your imagination is the limit!</p>
								</div>
							</div>
							<div className='vertical'></div>
							<div className='imageSection'>
								<div className='data'>
									<img src={processSec} alt='second-process' />
									<p className='heading'>Bet Goes Live</p>
									<p>
										If your bet is fun enough, another member will jump in and agree to your terms. Bet is now live and good luck!
									</p>
								</div>
							</div>
							<div className='vertical'></div>
							<div className='imageSection'>
								<div className='data'>
									<img src={processThird} alt='third-process' />
									<p className='heading'>Collect VNLA Rewards</p>
									<p>Real VNLA tokens, real rewards, which can be withdrawn at any time. Betting has changed forever.</p>
								</div>
							</div>
						</div>
					</div>
					<div className='vanillaNetworkContainer'>
						<div className='networkDataContainer'>
							<p className='heading'>Vanilla Network</p>
							<p>
								The Vanilla Network (token ticker: VNLA) is a deflationary BEP-20 token with decentralized finance at the heart of its
								ecosystem driven by Blockchain technology. The premise of this project is the production of award-winning staking and
								betting decentralized applications (dApps) that appeal to all betting enthusiasts and those seeking rewards for
								long-term participation as stakeholders. The overarching aim, by making use of Blockchain technology is to produce the
								first truly decentralized driven betting and staking platform where the house no longer wins, and all rewards are
								returned to the consumer.
							</p>
							<button onClick={() => history.push('/auth/register')}>GET STARTED</button>
						</div>
						<img
							src={devicesImage}
							alt='devices-img'
							style={{ width: '-webkit-fill-available', cursor: 'pointer' }}
							onClick={toggleOpen}
						/>
					</div>
					<div className='featuresContainer'>
						<div className='stack'>
							<div className='featureContainer'>
								<img src={scalability} alt='scalability-img' />
								<div className='data'>
									<p>Scalability</p>
									<p>
										Gaming protocols built on Layer-2 solutions provided by Matic Network. Allowing for high speed and low cost
										transactions, a breakthrough in Blockchain technology.
									</p>
								</div>
							</div>
							<div className='featureContainer'>
								<img src={userExperience} alt='user-experience-img' />
								<div className='data'>
									<p>User Experience</p>
									<p>
										Protocols designed for user adoption and ease of use; even though processes are built on high tech
										architecture and complicated framework.
									</p>
								</div>
							</div>
							<div className='featureContainer'>
								<img src={security} alt='security-img' />
								<div className='data'>
									<p>Security</p>
									<p>
										All protocols are built on-chain therefore, remain immutable on the blockchain. All smart contracts are audited by
										renowned blockchain professionals
									</p>
								</div>
							</div>
						</div>
						<div className='stack'>
							<div className='featureContainer'>
								<img src={allInOne} alt='all-in-one-img' />
								<div className='data'>
									<p>
										All-In-One Defi <span>Platform</span>
									</p>
									<p>
										Gaming protocols built on Layer-2 solutions provided by Matic Network. Allowing for high speed and low cost
										transactions, a breakthrough in Blockchain technology.
									</p>
								</div>
							</div>
							<div className='featureContainer'>
								<img src={multiplePlatforms} alt='multiple-platforms-img' />
								<div className='data'>
									<p>
										Multiple <span>Platforms</span>
									</p>
									<p>
										Protocols designed for user adoption and ease of use; even though processes are built on high tech
										architecture and complicated framework.
									</p>
								</div>
							</div>
							<div className='featureContainer'>
								<img src={responsive} alt='responsive-team-img' />
								<div className='data'>
									<p>
										Responsive <span>Support Team</span>
									</p>
									<p>
										All protocols are built on-chain therefore, remain immutable on the blockchain. All smart contracts are audited by
										renowned blockchain professionals
									</p>
								</div>
							</div>
						</div>
						<div className='stack'>
							<div className='featureContainer'>
								<div className='data'>
									<p style={{ textAlign: 'center' }}>Ready to place bets with our awesome community? We're waiting for you!</p>
									<button onClick={() => window.open('https://vanilla.network', '_blank', 'noreferrer')}>GET STARTED</button>
								</div>
							</div>
						</div>
					</div>
					<div className='questionsContainer'>
						<p id='important'>Frequently Asked Questions</p>
						<div className='options-container'>
							<p onClick={() => setCurrsection(1)}>How It Works</p>
							<p onClick={() => setCurrsection(2)}>Getting Started</p>
							<p onClick={() => setCurrsection(3)}>Deposits & Withdrawals</p>
							<p onClick={() => setCurrsection(4)}>Support</p>
						</div>
						<div className='questionsSection'>{getQuestions(currsection)}</div>
					</div>
					<div className='newsletterContainer'>
						<p id='heading'>Subscribe to stay up to date with Vanilla Network!</p>
						<div id='emailContainer'>
							<input type='email' name='email' placeholder='Enter email address ' />
							<button>Subscribe </button>
						</div>
						<p id='disclaimer'>
							* By submitting this form you are agreeing to receive communications from Vanilla Network. To see how we use your
							information please see our privacy policy
						</p>
					</div>
				</div>
				<div className='footerContainer'>© 2021 Vanilla Network. All Rights Reserved</div>
			</div>
			<Player {...{ open, toggleOpen }} />
		</>
	)
}
