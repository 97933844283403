import { Button, makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		minHeight: 224,
		width: '100%',
	},
	button: {
		padding: '12px 24px',
		minWidth: '230px',
	},
}))
export const CustomButton: React.FC<{ label?: string; action?: () => void }> = ({ label, action } = { label: '', action: () => {} }) => {
	const classes = useStyles()
	return (
		<Button
			type='submit'
			onClick={() => {
				if (action) action()
			}}
			className={classes.button}
			variant='contained'
			color='primary'
		>
			{<span className='bold'>{label}</span>}
		</Button>
	)
}
