import React from 'react'
import { toast } from 'react-toastify'
import Container from 'typedi'
import { TreasuryABI, TreasuryAddress, TokenABI, TokenAddress } from '../config'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import { UserDataActions } from '../redux/slices/UserData'
// import { Bet } from '../types/Bet'
import { ERC20 } from '../types/ERC20'
// import { Migrations } from '../types/Migrations'
import { Treasury } from '../types/Treasury'
import { ContractsInstance } from '../utilities/getContractInstance'
import { getWeb3 } from '../utilities/getWeb3'

const contracts = Container.get(ContractsInstance)

export const LoadContractsHook = () => {
	const [updates, setUpdates] = React.useState(true)

	const isOwner = useAppSelector((state) => state.userDataReducer.isOwner)
	const primaryAccount = useAppSelector((state) => state.userDataReducer.primary)
	const dispatch = useAppDispatch()

	// const updateUserData = React.useCallback(
	// 	async (MigrationsInstance: Migrations) => {
	// 		if ((await MigrationsInstance.methods.owner().call()) === primaryAccount) {
	// 			dispatch(UserDataActions.setIsOwner(true))
	// 		}
	// 	},
	// 	[primaryAccount, dispatch]
	// )

	const load = React.useCallback(async () => {
		if (primaryAccount && updates) {
			try {
				const web3 = await getWeb3({ biconomy: false })
				// console.log('init web3')
				// const biconomy =
				;(window as any).b_ = web3
				// const MigrationsInstance = (new web3.eth.Contract(MigrationsABI as any, MigrationsAddress) as unknown) as Migrations

				const Token = (new web3.eth.Contract(TokenABI as any, TokenAddress) as unknown) as ERC20
				// console.log('init token')
				;(window as any).normal = Token
				const T = (new web3.eth.Contract(TreasuryABI as any, TreasuryAddress) as unknown) as Treasury
				// console.log('init treasury')
				// contracts.set_migration(MigrationsInstance)
				contracts.set_treasury(T)
				contracts.set_token(Token)
				// await updateUserData(MigrationsInstance)
				;(window as any).contracts = contracts

				// setLoaded(true)
				dispatch(UserDataActions.setContractsLoaded(true))
			} catch (err) {
				toast('Web3 not connected or error-ed. Connect Metamask.')
			}
			// const normalWeb3 = await getWeb3({ biconomy: false })
		}
	}, [primaryAccount, updates, dispatch])

	return {
		load,
		isOwner,
		updates,
		setUpdates,
	}
}
