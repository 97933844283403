import React, { useState } from 'react'
import './Support.scss'
import { API } from '../../api/API'
import { toast } from 'react-toastify'
import { LogoNav } from '../../components/LogoNav/LogoNav'
import { Input } from '../../components/Input/Input'

const Support: React.FC = () => {
	const initialState = {
		Email: '',
		Description: '',
	}
	const [SupportForm, setSupportForm] = useState(initialState)
	const onChangeHandler = (e: any) => {
		setSupportForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))
	}
	const onSubmitHandler = async () => {
		const { Email, Description } = SupportForm
		await API.support_form(Email, Description)
		// console.log(resp)
		toast('Query Submitted')
	}
	return (
		<div className='SUPPORT'>
			<div className='supportContainer'>
				<LogoNav />
				<div className='supportModal'>
					<h1 className='supportModal__head'> Report an Issue </h1>
					<form
						method='post'
						onSubmit={(e) => {
							e.preventDefault()
							onSubmitHandler()
						}}
					>
						<Input
							name='Email'
							value={SupportForm.Email}
							valueHandler={onChangeHandler}
							placeHolder='Enter Email Address'
							type='email'
							required
							tag='Email'
						/>
						<Input
							name='Description'
							value={SupportForm.Description}
							valueHandler={onChangeHandler}
							placeHolder='Enter Description'
							type='textarea'
							required
							tag='Description'
						/>
						<button className='supportModal__btn'>Submit</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default Support
