import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom'
// import { CreateBet } from './components/CreateBet'
// import { Nav } from './components/Nav/Nav'
// import { PlaceBet } from './components/PlaceBet'

import { LoadContractsHook } from './hooks/LoadContractsHook'
import { Home } from './pages/Home/Home'
import { Auth } from './pages/Auth/Auth'
// import { DeclareResult } from './components/DeclareResult'
import { Landing } from './pages/Landing/Landing'
import { Layout } from './pages/admin/Layout'
import { useAppSelector } from './redux/hooks'
import Forgot from './pages/Auth/Forgot'
import { parse } from 'query-string'
import { API } from './api/API'
import Support from './pages/Support/Support'
// import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'

export const LoadContracts: React.FC<{}> = ({ children }) => {
	const loaded = useAppSelector((state) => state.userDataReducer.contracts_loaded)
	const { load } = LoadContractsHook()

	React.useEffect(() => {
		load()
	}, [load])

	// React.useEffect(() => {
	// 	console.log({ loaded })
	// }, [loaded])

	return <Fragment>{loaded && children}</Fragment>
}

const Verify: React.FC = () => {
	const { search } = useLocation()
	const { push } = useHistory()
	React.useEffect(() => {
		const run = async (token: string) => {
			await API.verify_email(token)
			alert('Verified. Please log in to continue.')
			push('/auth')
		}
		if (search) {
			const { token } = parse(search)
			if (token) {
				run(token as string)
			}
		}
	}, [search, push])
	return <></>
}

export const Routes: React.FC = () => {
	return (
		<>
			{/* <Dialog open={true}>
				<DialogTitle>Under Maintenance</DialogTitle>
				<DialogContent>
					<p>Preparing for Final Upgrade and Public Release.</p>
					<p>For more information, please visit:</p>
					<a href='https://discord.gg/V8qb5XcN'>Discord</a>
					<a href='https://t.me/Vanillafinance'>Telegram</a>
				</DialogContent>
				<DialogActions>
					
				</DialogActions>
			</Dialog> */}

			<LoadContracts />
			<Router>
				<Switch>
					<Route exact path='/' component={Landing} />
					<Route path='/verify'>
						<Verify></Verify>
					</Route>
					<Route path='/dashboard' render={(props) => <Home {...props} />} exact></Route>
					<Route path='/dashboard/transactions' exact render={(props) => <Home transactions={true} {...props} />} />
					<Route path='/dashboard/:id' render={(props) => <Home shared={true} {...props} />} />
					<Route path='/support' component={Support} />
					<Route path='/auth' component={Auth} />
					<Route path='/forgot/:token' component={Forgot} />
					{/* Testing Routes */}
					<Route path='/admin' render={(props) => <Layout {...props} />} />
					<Redirect path="/*" to="/" />
				</Switch>
			</Router>
		</>
	)
}
