import React, { useState, useCallback } from 'react'
import './Auth.scss'
import { Register } from './Register/Register'
import { Login } from './Login/Login'
import { API } from '../../api/API'
import { RouteComponentProps, useLocation } from 'react-router'
import { LogoNav } from '../../components/LogoNav/LogoNav'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { toast } from 'react-toastify'
import { MetaMaskHook } from '../../hooks/MetaMaskHook'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { login, setUID } from '../../redux/slices/UserLogin'

const FormData = require('form-data')

export const Auth: React.FC<RouteComponentProps> = ({ history }) => {
	const { accounts, run } = MetaMaskHook()
	const [currAuth, setCurrAuth] = useState<'login' | 'register'>('login')
	const [loginForm, setLoginForm] = useState<{ email: string; password: string }>({
		email: '',
		password: '',
	})

	const primaryAccount = useAppSelector((state) => state.userDataReducer.primary)
	const dispatch = useAppDispatch()

	const [registration_form, setRegForm] = useState<{
		ethereum_address: string
		email: string
		password: string
		confP: string
		name: string
		file: any
		username: string
	}>({
		ethereum_address: '',
		email: '',
		password: '',
		confP: '',
		name: '',
		username: '',
		file: null,
	})

	const location = useLocation()
	React.useEffect(() => {
		setCurrAuth(location.pathname.includes('register') ? 'register' : 'login')
	},[location])

	React.useEffect(() => {
		setRegForm((regForm) => ({ ...regForm, ethereum_address: primaryAccount }))
	}, [primaryAccount])

	const setLoginState = useCallback((e) => {
		setLoginForm((formdata) => ({
			...formdata,
			[e.target.name]: e.target.value,
		}))
	}, [])

	const setRegState = useCallback((e: React.ChangeEvent<HTMLInputElement>, type: string = 'input') => {
		let value: any
		if (type === 'file') {
			value = (e as any).target.files[0] ?? ''
		} else {
			value = e.target.value
		}
		setRegForm((formdata) => {
			return {
				...formdata,
				[e.target.name]: value,
			}
		})
		// console.log(value)
	}, [])

	const handleClickLogin = async () => {
		try {
			const { email, password } = loginForm
			// console.log(loginForm)
			const resp = await API.login({ email, password })
			if (resp) {
				Object.entries(resp.data).forEach(([key, value]) => {
					localStorage.setItem(key, `${value}`)
				})
				dispatch(login(true))
				dispatch(setUID(resp.data.uid))
				history.push('/dashboard')
			} else {
				alert('Failed to login')
			}
		} catch (err) {
			console.error(err)
		}
	}

	const handleClickRegister = async () => {
		try {
			const { email, password, confP: confirm_password, name, file, ethereum_address, username } = registration_form
			if (!ethereum_address) {
				toast.info('Connect metamask first')
				return
			}
			const formData = { email, password, name, username, file, ethereum_address }
			const form = new FormData()
			Object.entries(formData).forEach(([key, value]) => {
				if (value)
					form.append(key, value)
			})

			if (confirm_password === password) {
				await API.register(form)
				toast.success('Registered Successfully')
				toast.info('Please open the verification link sent to your email address to complete the verification process', {
					autoClose: false,
					closeOnClick: true,
				})
				setCurrAuth('login')
			} else {
				toast.error('Passwords do not match')
			}
		} catch (err) {
			console.error(err)
		}
	}

	const handleCancel = () => {
		history.push('/')
	}

	return (
		<div className='AUTH'>
			<div className='authContainer'>
				<LogoNav />
				<div className='componentContainer'>
					<p style={{ textAlign: 'center', color: 'white', padding: '15px 0' }}>Wallet {primaryAccount.slice(0,6) + "..." + primaryAccount.slice(-4)}</p>
					<div className='headingContainer'>
						<div className={`headers ${currAuth === 'login' ? 'active' : null}`} onClick={() => setCurrAuth('login')}>
							<p>Sign In</p>
						</div>
						<div className={`headers ${currAuth === 'register' ? 'active' : null}`} onClick={() => setCurrAuth('register')}>
							<p>Sign Up</p>
						</div>
					</div>

					{currAuth === 'login' ? (
						<Login
							changeHandler={setLoginState}
							state={loginForm}
							onClick={() => {
								handleClickLogin()
							}}
						/>
					) : (
						<Register
							state={registration_form}
							changeHandler={setRegState}
							onClick={() => {
								handleClickRegister()
							}}
						/>
					)}
				</div>
			</div>
			<Dialog open={!accounts.length}>
				<DialogTitle>MetaMask not connected</DialogTitle>
				<DialogContent>
					Vanilla betting requires an active connection to your MetaMask wallet. 
					Please connect your wallet and press confirm.
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>Cancel</Button>
					<Button onClick={run}>Confirm</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
