import { createSlice } from "@reduxjs/toolkit";

export const NotificationSlice = createSlice({
    name: "notification",
    initialState: {
        message: "",
        unread: 0
    },
    reducers: {
        setMessage: (state, action) => {
            state.unread = state.unread + 1;
            state.message = action.payload;
        },
        resetUnread: (state, _action) => {
            state.unread = 0;
        }
    }
})

export const { setMessage, resetUnread } = NotificationSlice.actions;
export default NotificationSlice.reducer;