import { faCopy, faEdit, faEllipsisV, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { H2H } from '../../../../api/H2H'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { UserDataActions } from '../../../../redux/slices/UserData'
import { BN } from '../../../../utilities/BN'
import { HtoHStatusEnum } from '../../../admin/HtoHStatusEnum'
import { CreateNewBet } from '../CreateNewBet/CreateNewBet'
import { PropInt } from './WalletTransactions'

export default function BetActions({ id: bet }: Pick<PropInt, 'id'>) {
	const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const uid = useAppSelector(state => state.userLoginReducer.uid)
	const dispatch = useAppDispatch()

	const toggleOpen = (e: React.SyntheticEvent<any>) => {
		try {
			e.preventDefault()
			e.stopPropagation()
		} catch (error) { }
		setOpen(o => !o)
	}

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(e.currentTarget);
	};

	const handleClose = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		setAnchorEl(null);
	};

	const handleDelete = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.preventDefault()
		e.stopPropagation()
		if (window.confirm('Are you sure you want to delete this bet?')) {
			H2H.deleteBet(bet.uid)
				.then(() => dispatch(UserDataActions.setUpdate()))
				.catch(() => { })
		}
	}

	const handleCopy = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.preventDefault()
		e.stopPropagation()
		return navigator.clipboard
			.writeText(`${window.location.protocol + '//' + window.location.host}/dashboard/${bet.uid}`)
			.then(() => toast.info('Copied to clipboard'))
			.catch((err) => toast.error(err.message))
	}

	if (uid !== bet.bettor_a.uid) return <></>
	return (
		<>
			<IconButton onClick={handleClick}>
				<FontAwesomeIcon icon={faEllipsisV} />
			</IconButton>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={handleCopy}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faCopy} />
					</ListItemIcon>
					<ListItemText primary='Share' />
				</MenuItem>
				{HtoHStatusEnum[bet.status] !== HtoHStatusEnum[2] && HtoHStatusEnum[bet.status] !== HtoHStatusEnum[3]  && (
					<MenuItem onClick={toggleOpen}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faEdit} />
						</ListItemIcon>
						<ListItemText primary='Edit' />
					</MenuItem>
				)}
				{uid === bet.bettor_a.uid && bet.status === HtoHStatusEnum.OpenForBetting && (!bet.bettor_b || (bet.private && !bet.betted)) && (
					<MenuItem onClick={handleDelete}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faTrash} />
						</ListItemIcon>
						<ListItemText primary='Delete' />
					</MenuItem>
				)}
			</Menu>
			<Dialog open={open} onClose={toggleOpen}>
				<DialogContent>
					<CreateNewBet
						{...bet}
						amount={new BN(bet.amount).dividedBy('1e18').toFormat(2)}
						end={new Date(bet.end).toLocaleString('sv').substring(0, 16).replace(' ', 'T')}
						result={new Date(bet.result || new Date()).toLocaleString('sv').substring(0, 16).replace(' ', 'T')}
						bettor_b={bet.bettor_b?.username || ''}
						isPvt={bet.private}
						handleClose={toggleOpen}
						editable={open === true ? true : false}
					/>
				</DialogContent>
			</Dialog>
		</>
	)
}
