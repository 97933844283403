import React from 'react'
import { useDispatch } from 'react-redux'
import { get_static_url } from '../../../../api/API'
import { UserDataActions } from '../../../../redux/slices/UserData'
import { vbetShortLogo } from '../../../../assets/index'
import './RecentBets.scss'

interface PropInt {
	fOpp: string
	sOpp: string
	fOppImg: string
	sOppImg: string
	data: string
	exp: string
	status: string
	bet_amount: string
	betName: string
	uid: string
	admin: boolean
}

export const RecentBets: React.FC<PropInt> = ({ fOpp, sOpp, fOppImg, sOppImg, data, exp, status, bet_amount, betName, uid, admin }) => {
	const dispatch = useDispatch()
	return (
		<div className='RBets'>
			<div className='recentBetsContainer'>
				<div className='topContainer'>
					{betName}
					<div className='oppContainer'>
						<div className='fOpp'>
							<p>{fOpp}</p>
							<img
								style={{ borderRadius: '50%' }}
								className='image_placeholder'
								width={50}
								height={50}
								src={get_static_url(fOppImg)}
								alt='opp-1'
							/>
						</div>
						{sOpp !== '' && <div className='divider'>VS</div>}
						<div className='sOpp'>
							{sOppImg !== '' && (
								<img
									className='image_placeholder'
									src={get_static_url(sOppImg)}
									alt='opp-1'
									width={50}
									height={50}
									style={{ borderRadius: '50%' }}
								/>
							)}
							<p>{sOpp}</p>
						</div>
					</div>
					{sOpp === '' && (
						<button
							onClick={(e) => {
								e.preventDefault()
								dispatch(UserDataActions.setBetUid(uid))
							}}
						>
							BET : {bet_amount} VNLA
						</button>
					)}
				</div>
				<div className='centerContainer'>{data}</div>
				<div className='dataBottomContainer'>
					<div className='bottomContainer'>
						<p>{exp}</p>
						<div className={`${status}`}>{status}</div>
					</div>
					{admin && (
						<div className='adminContainer'>
							<img src={vbetShortLogo} alt='admin-img' />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
