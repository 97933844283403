import { Service } from 'typedi'

import { ERC20 } from '../types/ERC20'
import { Migrations } from '../types/Migrations'
import { Treasury } from '../types/Treasury'

@Service()
export class ContractsInstance {
	private migrations: Migrations | null
	private treasury: Treasury | null
	private token: ERC20 | null

	constructor() {
		this.migrations = null
		this.treasury = null
		this.token = null
	}

	set_migration(migrations: Migrations) {
		this.migrations = migrations
	}

	set_treasury(treasury: Treasury) {
		this.treasury = treasury
	}

	set_token(token: ERC20) {
		this.token = token
	}

	get_migration(): Migrations {
		if (this.migrations) return this.migrations
		else throw new Error('Migrations not initialized.')
	}

	get_treasury(): Treasury {
		if (this.treasury) return this.treasury
		else throw new Error('Treasury Factory not loaded.')
	}

	get_token(): ERC20 {
		if (this.token) return this.token
		else throw new Error('Token not loaded.')
	}
}
