import React, { Fragment, useEffect, useState } from 'react'
import { WalletTransactions } from './components/WalletTransactions/WalletTransactions'

// import Container from 'typedi'
// import { ContractsInstance } from '../../utilities/getContractInstance'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

// import { bignum } from './Home'
import { BetType } from '../../api/BetType'
import { H2H } from '../../api/H2H'
import { Overwrite } from 'utility-types'
import { UserType } from '../../api/UserType'
import { Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from '@material-ui/core'
import axios from 'axios'
import { HtoHStatusEnum } from '../admin/HtoHStatusEnum'
import { resetUnread } from '../../redux/slices/Notification'
// import { HtoHStatusEnum } from '../admin/HtoHStatusEnum'

const betTypes = ['OPEN BETS', 'CLOSED BETS', 'MY BETS', 'RESULTS', 'MY DIRECT BETS'] as const

export const BetsListing = () => {
	const [bets, setBets] = useState<(Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }> & { created?: boolean })[]>([])
	const [categories, setCategories] = useState<{ [k: string]: string[] }>({})
	const [selectedCategories, setSelectedCategories] = useState<string[]>([])
	const [selected, setSelected] = useState<typeof betTypes[number]>('OPEN BETS')
	const { update, unread } = useAppSelector(state => ({
		update: state.userDataReducer.update,
		unread: state.notificationReducer.unread,
	}))
	const { loggedIn, uid } = useAppSelector((state) => state.userLoginReducer)
	const dispatch = useAppDispatch()
	// const primary = useAppSelector((state) => state.userDataReducer.primary)
	// const contracts_loaded = useAppSelector((state) => state.userDataReducer.contracts_loaded)

	// const withdraw = async (instance_counter: any) => {
	// 	if (primary && contracts_loaded) {
	// 		const contracts = Container.get(ContractsInstance)
	// 		const bet = contracts.get_bet()
	// 		await bet.methods.withdraw(instance_counter).send({ from: primary })
	// 		alert('Withdrawn')
	// 	}
	// }

	const run = React.useCallback(async () => {
		if (selected === 'MY BETS') {
			setBets(await H2H.getMyBetsAll())
		} else if (selected === 'RESULTS') {
			setBets(await H2H.getMyBets())
		} else if (selected === 'MY DIRECT BETS') {
			setBets(await H2H.getMyBetsRequested())
		} else {
			setBets(await H2H.getBets({ selectedCategories, open: selected === 'OPEN BETS' }))
		}
	}, [selected, selectedCategories])

	useEffect(() => {
		run()
	}, [update, selected, run, selectedCategories, unread])

	useEffect(() => {
		; (async () => {
			setCategories((await axios.get(process.env.REACT_APP_CATEGORIES_URI || '/categories')).data)
		})()
	}, [])

	const handleSelectedCategoriesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedCategories(event.target.value as string[])
	}

	const handleTabSelect = React.useCallback((e: React.ChangeEvent<{}>, v: any) => {
		setSelected(v)
		if (v === 'MY BETS') {
			dispatch(resetUnread(undefined))
		}
	}, [dispatch])

	return (
		<div className='BetsDisplayed'>
			{loggedIn && (
				<Tabs
					value={selected}
					onChange={handleTabSelect}
					variant='scrollable'
					scrollButtons='auto'
				>
					{
						betTypes.map((betType, i) => (
							<Tab label={betType + (betType === 'MY BETS' && unread ? ` [${unread}]` : '')} key={i} value={betType} />
						))
					}
				</Tabs>
			)}
			{(selected === 'OPEN BETS' || selected === 'CLOSED BETS') && (
				<FormControl style={{ minWidth: 320 }}>
					<InputLabel id='multi-category-select'>Categories</InputLabel>
					<Select multiple id='multi-category-select' value={selectedCategories} onChange={handleSelectedCategoriesChange}>
						{Object.keys(categories).map((key) => (
							<MenuItem key={key} value={key}>
								{key}
							</MenuItem>
						))}
						<MenuItem value='Other'>Other</MenuItem>
					</Select>
				</FormControl>
			)}
			{selected === 'MY BETS' && (
				<Fragment>
					<div className='card'>
						<div className='inner'>
							<div style={{ padding: '10px 0' }} className=''>
								<div className='WTC'>
									<div className='wtcContainer'>
										<p className='flex-inner'>Total Created : {bets.filter((elem) => elem.created === true).length}</p>
										<p className='flex-inner'>Total Bet : {bets.filter((elem) => elem.created === false).length}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
			<div className='card' style={{ background: '#F7F7F7', padding: 0 }}>
				<div className='inner'>
					{
						bets.reduce((acc, elem, i) => {
							if (HtoHStatusEnum[elem.status] === HtoHStatusEnum[5] && uid !== elem.bettor_a.uid) {
							} else {
								acc.push(
									<div key={elem.uid}>
										<WalletTransactions
											eth={`${elem.name}`}
											vnla={`${elem.amount}`}
											id={elem}
											status={elem.status}
											winner={elem.winner}
											approved={elem.approved}
											tag={
												elem.status === HtoHStatusEnum.ResultsDeclared ||
													elem.betted ||
													(elem?.bettor_b && !elem.private) ||
													new Date(elem.end) < new Date() ||
													elem.status === HtoHStatusEnum.Exited
													? 'CLOSED'
													: 'OPEN'
											}
											type={0}
										/>
										{i !== bets.length - 1 && (
											<Divider
												key={i}
												style={{
													margin: '1rem 0rem',
													height: '2px',
													background: `linear-gradient(100.46deg, #e20070 11.04%, #33008d 57.79%)`,
												}}
											/>
										)}
									</div>
								)
							}
							return acc
						}, [] as React.ReactElement<any>[])
					}
					{/* {bets.map((elem, i) => (
						<>
							<div key={i}>
								<WalletTransactions
									eth={`${elem.name}`}
									vnla={`${elem.amount}`}
									id={elem}
									status={elem.status}
									winner={elem.winner}
									approved={elem.approved}
									tag={
										elem.status === HtoHStatusEnum.ResultsDeclared ||
										elem?.bettor_b ||
										new Date(elem.end) < new Date() ||
										elem.status === HtoHStatusEnum.Exited
											? 'CLOSED'
											: 'OPEN'
									}
									type={0}
								/>
							</div>
							{i !== bets.length - 1 && (
								<Divider
									key={i}
									style={{
										margin: '1rem 0rem',
										height: '2px',
										background: `linear-gradient(100.46deg, #e20070 11.04%, #33008d 57.79%)`,
									}}
								/>
							)}
						</>
					))} */}
				</div>
			</div>
		</div>
	)
}
