import { Dialog, makeStyles } from "@material-ui/core";

interface PlayerProps {
    open: boolean
	toggleOpen: () => void
}

const useStyles = makeStyles(_t => ({
    dialog: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        paddingTop: '56.25%',
    },
    iframe: {
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        width: '100%',
        height: '100%',
        border: 'none'
    }
}))

export default function Player(props: PlayerProps){
    const classes = useStyles()
    return(
        <Dialog open={props.open} onClose={props.toggleOpen} fullWidth>
            <div className={classes.dialog}>
                <iframe
                    src='https://www.youtube-nocookie.com/embed/nwCFHvvqVf0'
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                    className={classes.iframe}
                ></iframe>
            </div>
        </Dialog>
    )
}