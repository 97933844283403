import axios from 'axios'
import { toast } from 'react-toastify'
import { Overwrite } from 'utility-types'
import { HtoHWinnerEnum } from '../pages/admin/HtoHWinnerEnum'
import { BetType } from './BetType'
import { UserType } from './UserType'

const API_LINK = (process.env.REACT_APP_API_LINK || 'http://localhost:8080/') + 'h2h/'

const resolve_url = (link: string) => {
	return new URL(link, API_LINK).href
}

export const get_static_url = (image: string) => {
	return resolve_url(`static/${image}`)
}

export class H2H {
	static async allowBettor(h2h_id: string) {
		try {
			return (
				await axios.post(
					resolve_url('allowBettor'),
					{
						h2h_id,
						bettor_b_id: localStorage.getItem('uid'),
					},
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (error) {
			H2H.handleError(error)
			// throw new Error('Error-ed')
		}
	}

	static async declareResults(uid: string, winner: HtoHWinnerEnum) {
		try {
			return await axios.post(
				resolve_url('declareResults'),
				{ uid, winner },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
		} catch (error: any) {
			if (error.response) {
				console.error(error.response?.data)
				console.error(error.response.status)
				if (error.response?.data?.message) {
					toast.error(error.response?.data?.message)
				}
			}
			console.error(error)
		}
	}

	static async getBet(bet_id: string) {
		try {
			return await axios.get(resolve_url(`getBet?uid=${bet_id}`), {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
		} catch (error: any) {
			if (error.response) {
				console.error(error.response?.data)
				console.error(error.response.status)
				if (error.response?.data?.message) {
					toast.error(error.response?.data?.message)
				}
			}
			console.error(error)
		}
	}

	static async getMyBetsAll() {
		try {
			return (
				await axios.post<(Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }> & { created: boolean })[]>(
					resolve_url('getMyBetsAll'),
					{
						uid: localStorage.getItem('uid'),
					},
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (error) {
			H2H.handleError(error)
			throw new Error('Error-ed')
		}
	}

	static async getMyBets() {
		try {
			return (
				await axios.post<Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }>[]>(
					resolve_url('getMyBets'),
					{
						uid: localStorage.getItem('uid'),
					},
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (error) {
			H2H.handleError(error)
			throw new Error('Error-ed')
		}
	}

	static async getMyBetsRequested() {
		try {
			return (
				await axios.post<Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }>[]>(
					resolve_url('getMyBetsRequested'),
					{
						uid: localStorage.getItem('uid'),
					},
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (error) {
			H2H.handleError(error)
			throw new Error('Error-ed')
		}
	}

	static async approve(body: { uid: string; approve: boolean }) {
		try {
			return (
				await axios.post(resolve_url('approveBet'), body, {
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				})
			).data
		} catch (error) {
			H2H.handleError(error)
			// throw new Error('Error-ed')
		}
	}

	static async getBetsAdmin() {
		try {
			return (
				await axios.get<BetType[]>(resolve_url('getBetsAdmin'), {
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				})
			).data
		} catch (error) {
			H2H.handleError(error)
			throw new Error('Error-ed')
		}
	}

	static async getBets(params?: object) {
		try {
			return (
				await axios.get<Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }>[]>(resolve_url('getBets'), {
					headers: {
						Authorization: localStorage.getItem('token'),
					},
					params: {
						...params,
					},
				})
			).data
		} catch (error) {
			H2H.handleError(error)
			throw new Error('Error-ed')
		}
	}

	static async createBet(betData: any) {
		try {
			return await axios.post(
				resolve_url('createBet'),
				{
					...betData,
					bettor_a: localStorage.getItem('uid'),
				},
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
		} catch (error) {
			H2H.handleError(error)
		}
	}

	static async createBetPrivate(betData: any) {
		try {
			return await axios.post(
				resolve_url('createBetPrivate'),
				{
					...betData,
					bettor_a: localStorage.getItem('uid'),
				},
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
		} catch (error) {
			H2H.handleError(error)
		}
	}

	static async editBet(betData: any) {
		try {
			return await axios.put(resolve_url('bet'), betData, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
		} catch (error) {
			H2H.handleError(error)
		}
	}

	static async deleteBet(uid: string) {
		try {
			return await axios.delete(resolve_url(`bet/${uid}`), {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
		} catch (error) {
			H2H.handleError(error)
		}
	}

	private static handleError(error: any) {
		if (error.response) {
			console.error(error.response?.data)
			console.error(error.response.status)
			if (error.response?.data?.message) {
				toast.error(error.response?.data?.message)
			}
		}
		console.error(error)
		throw new Error(error.response?.data?.message ?? '')
	}
}
