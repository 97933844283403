import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary as MuiAccordionSummary,
	CardContent,
	CardHeader as MuiCardHeader,
	CardHeaderProps,
	Chip,
	Grid,
	withStyles,
} from '@material-ui/core'
import React from 'react'
import { toast } from 'react-toastify'
import { Overwrite } from 'utility-types'
import { BetType } from '../../../../api/BetType'
import { H2H } from '../../../../api/H2H'
import { UserType } from '../../../../api/UserType'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { BN } from '../../../../utilities/BN'

import { UserDataActions } from '../../../../redux/slices/UserData'
import { HtoHStatusEnum } from '../../../admin/HtoHStatusEnum'
import { HtoHWinnerEnum } from '../../../admin/HtoHWinnerEnum'

import './WalletTransactions.scss'
import { useHistory } from 'react-router'
import BetActions from './BetActions'

export interface PropInt {
	eth: string
	id: Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }>
	vnla: string
	tag: string
	type: 0 | 1
	winner: any
	approved: null | boolean
	status: HtoHStatusEnum
}

const CardHeader = withStyles((_t) => ({
	content: {
		display: 'flex',
		flexDirection: 'column-reverse',
	},
}))((props: CardHeaderProps & { title: any }) => <MuiCardHeader {...props} titleTypographyProps={{ variant: 'h6' }} />)

const AccordionSummary = withStyles((_t) => ({
	root: { flexDirection: 'column' },
	content: { margin: 0, width: '100%' },
	expandIcon: { padding: 0 },
}))(MuiAccordionSummary)

export const WalletTransactions: React.FC<PropInt> = ({ status, eth, id, vnla, tag, type, winner, approved }) => {
	const { uid: myUid } = useAppSelector((state) => state.userLoginReducer)
	return (
		<Accordion>
			<AccordionSummary expandIcon={<FontAwesomeIcon icon={faAngleDown} />}>
				<Grid container justify='space-between' alignItems='center'>
					<Grid item xs={12}>
						<CardHeader
							title={eth}
							action={<BetActions id={id} />}
						/>
						<CardContent>Description: {id.description}</CardContent>
					</Grid>
					<Grid item xs>
						<CardHeader
							title={new BN(vnla).dividedBy('1e18').toFormat(2) + ' VNLA'}
							subheader={(id.private ? 'Private' : 'Public') + ' Bet'}
						/>
					</Grid>
					<Grid item xs>
						<CardHeader subheader='Bettor A' title={id.bettor_a.username ?? id.bettor_a.name} />
					</Grid>
					<Grid item xs>
						{id.bettor_b?.username || id.bettor_b?.name ? (
							<CardHeader subheader='Bettor B' title={id.bettor_b.username ?? id.bettor_b.name} />
						) : (
							<BetAgainst uid={id.uid} visible={tag !== 'CLOSED' && status === HtoHStatusEnum.OpenForBetting} bettor_a_uid={id.bettor_a.uid} />
						)}
						{/* <CardHeader subheader='Bettor B' title={id?.bettor_b?.username ?? id.bettor_b?.name ?? <>&nbsp;</>} /> */}
					</Grid>
					<Grid item xs={12} />
					<Grid item xs>
						<CardHeader
							subheader={
								<Chip
									label={tag}
									size='small'
									style={{
										padding: '1rem',
										borderRadius: 0,
										backgroundColor: tag === 'CLOSED' ? '#78fcd4' : 'rgba(255, 107, 0, 0.5)',
									}}
								/>
							}
							title={
								<p className='flex-inner' style={{ height: 'fit-content' }}>
									<span>
										{winner
											? 'Winner:' + HtoHWinnerEnum[winner]
											: status === HtoHStatusEnum.ResultsDeclared
												? 'Draw'
												: HtoHStatusEnum[id.status] === HtoHStatusEnum[5]
													? 'Deleted'
													: new Date(id.end) < new Date()
														? 'Closed For Betting'
														: HtoHStatusEnum[id.status] === 'OpenForBetting'
															? 'Open For Betting'
															: HtoHStatusEnum[id.status] === 'ClosedForBetting'
																? 'Closed For Betting'
																: HtoHStatusEnum[id.status]}
									</span>
								</p>
							}
						/>
					</Grid>
					<Grid item xs>
						{(id.bettor_b?.username || id.bettor_b?.name) && (
							<BetAgainst
								uid={id.uid}
								visible={
									tag !== 'CLOSED' &&
									status === HtoHStatusEnum.OpenForBetting &&
									myUid === id.bettor_b.uid
								}
								bettor_a_uid={id.bettor_a.uid}
							/>
						)}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container justify='space-between' alignItems='center'>
					<Grid item xs>
						<CardHeader subheader='Start Date' title={new Date(id.start).toLocaleString()} />
					</Grid>
					<Grid item xs>
						<CardHeader subheader='End Date' title={new Date(id.end).toLocaleString()} />
					</Grid>
					<Grid item xs>
						<CardHeader subheader='Result' title={new Date(id.result).toLocaleString()} />
					</Grid>
					<Grid item xs={12} />
					<Grid item xs>
						<CardHeader subheader='Category' title={id.category} />
					</Grid>
					<Grid item xs>
						<CardHeader subheader='Sub-Category' title={id.sub_category} />
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}

const BetAgainst = ({ uid, visible, bettor_a_uid }: { uid: string; visible: boolean; bettor_a_uid: string }) => {
	const { loggedIn, uid: myUid } = useAppSelector((state) => state.userLoginReducer)
	const dispatch = useAppDispatch()
	const history = useHistory()
	if (myUid === bettor_a_uid) return <></>
	return (
		<div
			onClick={async (e) => {
				e.preventDefault()
				e.stopPropagation()
				if (!window.confirm('Are you sure you want to place a counter-bet?')) return
				try {
					if (!loggedIn) {
						toast.info('Authentication required')
						return history.push('/auth')
					}
					await H2H.allowBettor(uid)
					toast.success('Bet against successfully')
					dispatch(UserDataActions.setUpdate())
				} catch (error) {
					console.error(error)
				}
			}}
			className='bttn flex-inner'
			{...(!visible && { style: { visibility: 'hidden' } })}
		>
			Bet Against
		</div>
	)
}
