import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContent, CardHeader, IconButton, TextField, Typography } from "@material-ui/core";
import { useState } from "react";

export default function Support({ closeButton = false, handleClose = () => {} }) {
    const [email, setEmail] = useState({
        to: 'support@vanilla.network',
        subject: '',
        body: ''
    });

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmail(prevEmail => ({
            ...prevEmail,
            [e.target.name]: e.target.value
        }))
    }

    return(
        <div>
            <CardHeader
                title='Support'
                titleTypographyProps={{ variant: 'h5' }}
                {...closeButton && {
                    action: (
                        <IconButton onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes as any}/>
                        </IconButton>
                    )
                }}
            />
            <CardContent>
                <Typography gutterBottom>
                    The Vanilla Bet platform has a dedicated service desk for all technical queries. For any issues experienced, please email our support team. We aim to respond within 24 hours of your query, and resolve the issue within 48 hours.
                </Typography>
                <TextField
                    fullWidth
                    name='to'
                    label='To'
                    variant='outlined'
                    value={email.to}
                    disabled
                    margin='dense'
                />
                <TextField
                    fullWidth
                    name='subject'
                    label='Subject'
                    variant='outlined'
                    value={email.subject}
                    onChange={handleChange}
                    margin='dense'
                />
                <TextField
                    fullWidth
                    name='body'
                    label='Body'
                    variant='outlined'
                    multiline
                    value={email.body}
                    onChange={handleChange}
                    margin='dense'
                />
                <a
                    href={
                        `mailto:${
                            email.to
                        }?subject=${
                            email.subject.replaceAll(" ", "%20")
                        }&body=${
                                email.body.replaceAll(" ", "%20")
                        }`
                    } 
                    className='bttn'
                    style={{ marginTop: 8 }}
                >
                    Send Email
                </a>
            </CardContent>
        </div>
    )
}