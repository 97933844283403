import React, { Fragment, useCallback, useEffect, useState } from 'react'
// import { toast } from 'react-toastify'
import { BetType } from '../../api/BetType'
import { H2H } from '../../api/H2H'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { UserDataActions } from '../../redux/slices/UserData'
import { HtoHStatusEnum } from './HtoHStatusEnum'
import { HtoHWinnerEnum } from './HtoHWinnerEnum'
import { BN } from '../../utilities/BN'
import { toast } from 'react-toastify'

export const DeclareBets: React.FC = () => {
	const [bets, setBets] = useState<Array<BetType>>([])

	const update = useAppSelector((state) => state.userDataReducer.update)
	const run = useCallback(async () => {
		setBets((await H2H.getBetsAdmin()).filter((elem) => new Date(elem.result).getTime() < Date.now()))
	}, [])

	useEffect(() => {
		run()
	}, [run, update])

	const keys = [
		'bettor_a',
		'bettor_b',
		'name',
		'description',
		'amount',
		'start',
		'end',
		'result',
		'Declare A',
		'Declare B',
		'Declare Draw',
		'status',
		'winner',
		'result',
	]

	// const handleApproval = async (bet_uid: string, approve: boolean) => {
	// 	console.log(await H2H.approve({ uid: bet_uid, approve }))
	// 	setUpdate((update) => update + 1)
	// }
	return (
		<Fragment>
			<div>Declare Bets Results</div>
			<table>
				<tr>
					{keys.map((heading) => {
						return (
							<Fragment>
								<th style={{ padding: '10px', textTransform: 'capitalize' }}>{heading}</th>
							</Fragment>
						)
					})}
				</tr>
				{bets.map((bet) => {
					return (
						<Fragment>
							<tr>
								{keys.map((heading) => {
									return BetListing(heading, bet)
								})}
							</tr>
						</Fragment>
					)
				})}
			</table>
		</Fragment>
	)
}
function BetListing(heading: string, bet: BetType): JSX.Element {
	const dispatch = useAppDispatch()
	const declare = async (winner: 'Declare A' | 'Declare B' | 'Declare Draw') => {
		try {
			toast('Declaring', { type: 'info', toastId: 'declaring-results' })

			if (winner === 'Declare A') {
				await H2H.declareResults(bet.uid, HtoHWinnerEnum.BettorA)
			}
			if (winner === 'Declare B') {
				await H2H.declareResults(bet.uid, HtoHWinnerEnum.BettorB)
			}
			if (winner === 'Declare Draw') {
				await H2H.declareResults(bet.uid, HtoHWinnerEnum.None)
			}
			toast.dismiss('declaring-results')
			toast('Declared', { type: 'success' })
			dispatch(UserDataActions.setUpdate())
		} catch (error) {
			console.error(error)
		}
		dispatch(UserDataActions.setUpdate())
	}

	if (heading === 'amount') {
		return (
			<Fragment>
				<td>{heading === 'amount' && new BN((bet as any)[heading]).dividedBy('1e18').toFormat(2)}</td>
			</Fragment>
		)
	}
	if (heading === 'winner') {
		return <td>{HtoHWinnerEnum[(bet as any)[heading]]}</td>
	}
	if (heading === 'status') {
		return (
			<Fragment>
				<td>{heading === 'status' && HtoHStatusEnum[(bet as any)[heading]]}</td>
			</Fragment>
		)
	}
	if (heading === 'start' || heading === 'end' || heading === 'result') {
		return <td style={{ padding: '10px' }}>{new Date((bet as any)[heading]).toLocaleString()}</td>
	}
	if (heading === 'Declare A' || heading === 'Declare B' || heading === 'Declare Draw') {
		if ((bet as any)['status'] === 0) {
			if (heading === 'Declare Draw') {
				return (
					<>
						<td className='btn'>
							<button
								onClick={async () => {
									// if (winner === 'Declare Draw') {
									await H2H.declareResults(bet.uid, HtoHWinnerEnum.None)
									toast('Declared', { type: 'success' })
									dispatch(UserDataActions.setUpdate())
									// }
								}}
								className='bttn'
								style={{ textTransform: 'capitalize', border: 'none' }}
							>
								Refund
							</button>
						</td>
					</>
				)
			}
			return (
				<>
					<td></td>
				</>
			)
		}
		if ((bet as any)['status'] === HtoHStatusEnum.ResultsDeclared || (bet as any)['status'] === HtoHStatusEnum.Exited) {
			return (
				<>
					<td></td>
				</>
			)
		}
		if (heading === 'Declare Draw') {
			return (
				<>
					<td className='btn'>
						<button
							onClick={async () => {
								// if (winner === 'Declare Draw') {
								toast('Declaring', { type: 'info' })
								await H2H.declareResults(bet.uid, HtoHWinnerEnum.None)
								// }
								toast('Declared', { type: 'success' })
								dispatch(UserDataActions.setUpdate())
							}}
							className='bttn'
							style={{ textTransform: 'capitalize', border: 'none' }}
						>
							Refund Both
						</button>
					</td>
				</>
			)
		}
		return (
			<Fragment>
				<td className='btn'>
					<button onClick={() => declare(heading)} className='bttn' style={{ textTransform: 'capitalize', border: 'none' }}>
						{heading}
					</button>
				</td>
			</Fragment>
		)
	}

	if (heading === 'bettor_a' || heading === 'bettor_b') {
		return (
			<Fragment>
				<td style={{ padding: '10px' }}>{(bet as any)[heading]?.username?.toString()}</td>
			</Fragment>
		)
	}

	return <td style={{ padding: '10px' }}>{(bet as any)[heading]?.toString()}</td>
}
