import { createSlice } from "@reduxjs/toolkit";

export const UserLoginSlice = createSlice({
    name: 'userLogin',
    initialState: {
        loggedIn: false,
        uid: '',
    },
    reducers: {
        login: (state, action) => {
            state.loggedIn = action.payload
        },
        setUID: (state, action) => {
            state.uid = action.payload
        }
    }
})

export const { login, setUID } = UserLoginSlice.actions;
export default UserLoginSlice.reducer;