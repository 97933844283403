import React from 'react'
import './Login.scss'
import { Input } from '../../../components/Input/Input'
import { AuthBtn } from '../../../components/AuthBtn/AuthBtn'
import { API } from '../../../api/API'
import { toast } from 'react-toastify'

interface PropsInt {
	state: {
		email: string
		password: string
	}
	changeHandler: (e: any) => void
	onClick: () => void
}

export const Login: React.FC<PropsInt> = ({ state, changeHandler, onClick }) => {
	const handleForgot = () => {
		// toast.info('Sending email')
		const email = window.prompt('Enter your email address')
		if (email) {
			return API.forgot(email)
				.then(() => toast.info('Password reset link emailed'))
				.catch(() => {})
		}
	}

	return (
		<div className='LOGIN'>
			<div className='loginContainer'>
				<form
					method='post'
					onSubmit={(e) => {
						e.preventDefault()
						onClick()
					}}
				>
					<Input
						name='Enter Email Here'
						value={state.email}
						valueHandler={changeHandler}
						placeHolder='Enter Email Address'
						type='email'
						required
						tag='email'
					/>
					<Input
						name='Password Here'
						value={state.password}
						valueHandler={changeHandler}
						placeHolder='Password Here'
						type='password'
						required
						tag='password'
					/>
					<AuthBtn clickHandler={() => {}}>Login</AuthBtn>
					<p onClick={handleForgot}>Forgot Password?</p>
				</form>
			</div>
		</div>
	)
}
