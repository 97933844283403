import { TextField, Card, CardContent, CardActions, Select, MenuItem, Switch, TextareaAutosize } from '@material-ui/core'
import React, { Fragment, useRef, useState } from 'react'
import { CustomButton } from '../CustomButton'

import './Form.scss'
// 'select-input'
type FieldType =
	| {
			name: string
			label: string
			selection: { value: string; label: string }[]
			type: 'select'
	  }
	| {
			name: string
			label: string

			type: string
	  }

export const Form: React.FC<{
	fields: FieldType[]
	action: ({ state }: { state: any }) => void
	text: string
	loading?: boolean
}> = ({ fields, action, text, loading = false, children }) => {
	const [state, setState] = React.useState({})

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type: string = '') => {
		let value = e.target.value

		if (type === 'date' || type === 'datetime-local') {
			const timestamp = new Date(e.target.value).getTime()
			// console.log(timestamp, e.target.value)
			value = `${timestamp}`
		}
		if (type === 'switch') {
			value = (e as any).target.checked
		}
		// console.log({ [e.target.name]: value })
		setState((state) => ({
			...state,
			[e.target.name]: value,
		}))
	}

	return (
		<Fragment>
			<Card variant='outlined'>
				<CardContent>
					{fields.map((elem) => {
						const { label, type, name } = elem
						if (type !== 'image' && type !== 'select' && type !== 'switch' && type !== 'textarea')
							return (
								<>
									<div className='wrapper'>
										<TextField
											id='outlined-basic'
											onChange={(e) => {
												handleChange(e, type)
											}}
											label={label}
											InputLabelProps={
												type === 'datetime-local' || type === 'date'
													? {
															shrink: true,
													  }
													: {}
											}
											variant='outlined'
											type={type}
											name={name}
											required
										/>
									</div>
								</>
							)
						else if (type === 'image') return <>{<AddFile {...{ setState, name, label }}></AddFile>}</>
						else if (type === 'select') {
							const { selection } = elem as {
								name: string
								label: string
								selection: { value: string; label: string }[]
								type: 'select-input'
							}
							return <>{SelectComponent({ label, name, handleChange, selection })}</>
						} else if (type === 'switch') {
							return (
								<>
									<div className='wrapper'>
										{label}:
										<Switch
											onChange={(e) => {
												handleChange(e, type)
											}}
											onLoad={(e: any) => {
												handleChange(e, type)
											}}
											name={name}
											color='default'
											inputProps={{ 'aria-label': 'checkbox with default color' }}
										/>
									</div>
								</>
							)
						} else if (type === 'textarea') {
							return (
								<div className='wrapper'>
									<TextareaAutosize
										onChange={(e: any) => {
											handleChange(e)
										}}
										name={name}
										aria-label='minimum height'
										rowsMin={3}
										placeholder={label}
									/>
								</div>
							)
						} else {
							return <></>
						}
					})}
					<div className='wrapper'>
						<CustomButton action={() => action({ state })} label={text} />
					</div>
					{children}
				</CardContent>
				<CardActions></CardActions>
			</Card>
		</Fragment>
	)
}

function SelectComponent({
	label,
	name,
	handleChange,
	selection,
}: {
	label: string
	name: string
	handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, type?: string) => void
	selection: { value: string; label: string }[]
}) {
	const [innerSelection, setInnerSelection] = useState<any[]>([])

	React.useEffect(() => {
		setInnerSelection(selection)
	}, [selection])

	return (
		<div className='wrapper' style={{ display: 'block' }}>
			<div className=''>{label}:</div>
			<br />
			<input
				type='text'
				onChange={(e) => {
					const filter = e.target.value.toUpperCase()
					if (filter) {
						const inner = selection.filter((elem) => elem.label.toUpperCase().indexOf(filter) > -1)
						setInnerSelection(inner)
						// console.log(inner.map((elem) => elem.label))
					} else {
						setInnerSelection(selection)
					}
				}}
			/>
			<Select
				name={name}
				style={{ minWidth: '100%' }}
				labelId={label}
				onChange={(e: any) => {
					handleChange(e)
				}}
			>
				{innerSelection.map((elem) => {
					return <MenuItem value={elem.value}>{elem.label}</MenuItem>
				})}
			</Select>
		</div>
	)
}

function AddFile({ setState, name, label }: { setState: React.Dispatch<React.SetStateAction<{}>>; name: string; label: string }) {
	const ref = useRef<HTMLInputElement>(null)
	return (
		<div className='wrapper'>
			<CustomButton
				action={() => {
					if (ref.current) {
						ref.current.click()
					}
				}}
				label={label}
			></CustomButton>
			<input
				ref={ref}
				required
				hidden
				onChange={(e) => {
					const files = e.target.files
					if (files !== null) {
						setState((state) => ({
							...state,
							[e.target.name]: files[0],
						}))
					}
				}}
				type='file'
				name={name}
			/>
		</div>
	)
}
