import { Container } from 'typedi'
import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { LoadContractsHook } from '../../hooks/LoadContractsHook'
import { useAppSelector } from '../../redux/hooks'
// import { CreateBet } from '../../components/CreateBet'
// import { DeclareResult } from '../../components/DeclareResult'
import { ApproveBets } from './ApproveBets'
import { DeclareBets } from './DeclareBets'
import { Form } from './Form'
// import { useAppSelector } from '../../redux/hooks'
import { ContractsInstance } from '../../utilities/getContractInstance'
// import { Form } from './Form'
import { VerticalTabs } from './SideBar'
import { WithdrawRequests } from './WIthdrawRequests'
import { API } from '../../api/API'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { TransactionListByUser } from './TransactionListByUser'

const contracts = Container.get(ContractsInstance)

export const TransactionListAdmin: React.FC = () => {
	const [descriptions, setDescriptions] = React.useState<{ description: string; createdAt: string }[]>([])
	const update = useAppSelector((state) => state.userDataReducer.update)

	React.useEffect(() => {
		const run = async () => {
			setDescriptions(await API.getTransactionsAdmin())
		}
		run()
	}, [update])
	return (
		<>
			{descriptions && <h1>Transactions</h1>}
			{descriptions.map((elem) => (
				<Fragment>
					<div style={{ lineHeight: 2 }} className=''>
						{new Date(elem.createdAt).toLocaleString()} - {elem.description}
					</div>
				</Fragment>
			))}
		</>
	)
}

export const Layout: React.FC<RouteComponentProps> = () => {
	const primary = useAppSelector((state) => state.userDataReducer.primary)
	const contracts_loaded = useAppSelector((state) => state.userDataReducer.contracts_loaded)

	React.useEffect(() => {
		if (!localStorage.getItem('admin') || localStorage.getItem('admin') === 'false') {
			window.location.href = '/'
		}
	}, [])

	const { load } = LoadContractsHook()

	React.useEffect(() => {
		if (!contracts_loaded) {
			load()
		}
	}, [load, contracts_loaded])

	const addAdmin = async ({
		state: { wallet_address, enabled, email, withApproval }
	}: {
		state: { wallet_address: string; enabled?: boolean; email: string, withApproval: boolean }
	}) => {
		if (enabled === undefined) {
			enabled = false
		}

		if (primary && contracts_loaded) {
			// const contract = getContract()
			try {
				if(withApproval) {
					const txn = await contracts
						.get_treasury()
						.methods.grantRole(await contracts.get_treasury().methods.ADMIN_ROLE().call(), wallet_address)
						.send({ from: primary })
				}
				await API.update_admin({ email, enabled, wallet: wallet_address })
				// toast.success(
				// 	'TotalAdmins:' +
				// 	(await contracts
				// 		.get_treasury()
				// 		.methods.getRoleMemberCount(await contracts.get_treasury().methods.ADMIN_ROLE().call())
				// 		.call())
				// )
				if(enabled)
					toast.success("Admin set successfully")
				else 
					toast.success("Admin removed successfully")
			} catch (err: any) {
				console.error(err)
				toast.error(err?.message || 'Could not add admin. Is address valid?')
			}
		} else {
			// error('Connect Metamask')
		}
	}

	const updateDeposit = async ({ state }: { state: any }) => {
		// console.log({ state })
		// console.log(Web3.utils.toWei(state.amount).toString())
		//
		if (await API.deposit_admin({ ...state, amount: Web3.utils.toWei(state.amount) })) toast('Added.')
	}

	const setToZero = async ({ state }: { state: any }) => {
		// console.log({ state })
		// console.log(Web3.utils.toWei(state.amount).toString())
		//

		return toast.error('Removed functionality')
		// const confirm = window.confirm(`Are you sure you want to set withdrawable balance of ${state.email} to zero?`)
		// if (confirm) {
		// 	if (await API.withdraw_admin(state.email)) toast('Set to Zero.')
		// }
	}
	return (
		<Fragment>
			<VerticalTabs
				items={[
					{
						label: `Approve Bets`,
						child: <Fragment>{<ApproveBets />}</Fragment>,
					},
					{
						label: `Declare Results`,
						child: (
							<Fragment>
								<DeclareBets></DeclareBets>
							</Fragment>
						),
					},
					{
						label: `Withdrawal Requests`,
						child: (
							<Fragment>
								<WithdrawRequests />
							</Fragment>
						),
					},
					{
						label: `Add Admin Wallet`,
						child: (
							<Fragment>
								<Form
									text='Add wallet'
									action={({ state }) => {
										// console.log({state})
										addAdmin({ state })
									}}
									fields={[
										{
											type: 'text',
											label: 'Wallet Address',
											name: 'wallet_address',
										},
										{
											type: 'text',
											label: 'Email Address',
											name: 'email',
										},
										{
											type: 'switch',
											label: 'Enabled',
											name: 'enabled',
										},
									]}
								></Form>
							</Fragment>
						),
					},
					{
						label: `Add Admin with Approval`,
						child: (
							<Fragment>
								<Form
									text='Add wallet with Approval'
									action={({ state }) => {
										state.withApproval = true
										// console.log({ state })
										// const {wallet_address, email, enabled, withApproval} = state
										// console.log({wallet_address, email, enabled, withApproval})
										addAdmin({ state })
									}}
									fields={[
										{
											type: 'text',
											label: 'Wallet Address',
											name: 'wallet_address',
										},
										{
											type: 'text',
											label: 'Email Address',
											name: 'email',
										},
										{
											type: 'switch',
											label: 'Enabled',
											name: 'enabled',
										},
									]}
								></Form>
							</Fragment>
						),
					},
					{
						label: 'Transactions',
						child: <TransactionListAdmin></TransactionListAdmin>,
					},
					{
						label: 'Transactions Email',
						child: <TransactionListByUser></TransactionListByUser>,
					},
					{
						label: 'Approve Deposits',
						child: (
							<Fragment>
								<Form
									text='Add Deposit'
									action={({ state }) => {
										updateDeposit({ state })
										// txnHash: string, amount: string, ethereum_address: string
									}}
									fields={[
										{
											type: 'text',
											label: 'Amount',
											name: 'amount',
										},
										{
											type: 'text',
											label: 'Ethereum Address',
											name: 'ethereum_address',
										},
										{
											type: 'text',
											label: 'Transaction Hash',
											name: 'txnHash',
										},
									]}
								></Form>
							</Fragment>
						),
					},
					{
						label: 'Set To ZERO',
						child: (
							<Fragment>
								{/* <div style={{ color: 'red' }} className=''>
									Updating database!
								</div> */}
								<Form
									text='Set to Zero'
									action={({ state }) => {
										setToZero({ state })
										// txnHash: string, amount: string, ethereum_address: string
									}}
									fields={[
										{
											type: 'email',
											label: 'Email',
											name: 'email',
										},
									]}
								></Form>
							</Fragment>
						),
					},
				]}
			></VerticalTabs>
		</Fragment>
	)
}
