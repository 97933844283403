import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { BetType } from '../../api/BetType'
import { H2H } from '../../api/H2H'
import { HtoHStatusEnum } from './HtoHStatusEnum'

import { BN } from '../../utilities/BN'
import { HtoHWinnerEnum } from './HtoHWinnerEnum'
import { toast } from 'react-toastify'

export const ApproveBets: React.FC = () => {
	const [bets, setBets] = useState<Array<BetType>>([])
	const [update, setUpdate] = useState(0)
	const run = useCallback(async () => {
		setBets(await H2H.getBetsAdmin())
	}, [])

	useEffect(() => {
		run()
	}, [run, update])

	const keys = [
		'bettor_a',
		'name',
		'description',
		'amount',
		'start',
		'end',
		'result',
		'approve',
		'deny',
		'approved',
		'delete',
		'status',
		'bettor_b',
	]

	const handleApproval = async (bet_uid: string, approve: boolean) => {
		// console.log(await H2H.approve({ uid: bet_uid, approve }))
		await H2H.approve({ uid: bet_uid, approve })
		setUpdate((update) => update + 1)
	}
	return (
		<Fragment>
			<div>Approve Bets</div>
			<table>
				<tr>
					{keys.map((heading) => {
						return (
							<Fragment>
								<th style={{ padding: '10px', textTransform: 'capitalize' }}>{heading}</th>
							</Fragment>
						)
					})}
				</tr>
				{bets.map((bet) => {
					return (
						<Fragment>
							<tr>
								{keys.map((heading) => {
									return <BetList setUpdate={setUpdate} heading={heading} bet={bet} handleApproval={handleApproval} />
								})}
							</tr>
						</Fragment>
					)
				})}
			</table>
		</Fragment>
	)
}
function BetList({
	heading,
	bet,
	setUpdate,
	handleApproval,
}: {
	heading: string
	bet: BetType
	setUpdate: any
	handleApproval: (bet_uid: string, approve: boolean) => Promise<void>
}): JSX.Element {
	const handleDelete = async (bet_id: string) => {
		// if (winner === 'Declare Draw') {
		try {
			await H2H.declareResults(bet_id, HtoHWinnerEnum.None)
			toast('Declared', { type: 'info' })
			setUpdate((update: any) => update + 1)
		} catch (err) {
			console.error(err)
		}
		// }
	}
	if (heading === 'amount') {
		return (
			<Fragment>
				<td>{heading === 'amount' && new BN((bet as any)[heading]).dividedBy('1e18').toFormat(2)}</td>
			</Fragment>
		)
	} else if (heading === 'status') {
		return (
			<Fragment>
				<td>{heading === 'status' && HtoHStatusEnum[(bet as any)[heading]]}</td>
			</Fragment>
		)
	} else if (heading === 'delete') {
		return (
			<>
				<td className='btn'>
					<button style={{ textTransform: 'capitalize' }} onClick={() => handleDelete(bet.uid)}>
						{heading}
					</button>
				</td>
			</>
		)
	} else
		return (
			<Fragment>
				{heading === 'status' && HtoHStatusEnum[(bet as any)[heading]]}
				{heading === 'bettor_a' || heading === 'bettor_b' ? (
					<Fragment>
						<td style={{ padding: '10px' }}>{(bet as any)[heading]?.name?.toString()}</td>
					</Fragment>
				) : (
					<Fragment>
						{heading === 'approve' || heading === 'deny' ? (
							<Fragment>
								<td className='btn'>
									<button style={{ textTransform: 'capitalize' }} onClick={() => handleApproval(bet.uid, heading === 'approve')}>
										{heading}
									</button>
								</td>
							</Fragment>
						) : (
							<Fragment>
								{heading === 'start' || heading === 'end' || heading === 'result' ? (
									<td style={{ padding: '10px' }}>{new Date((bet as any)[heading]).toLocaleString()}</td>
								) : (
									<td style={{ padding: '10px' }}>{(bet as any)[heading]?.toString()}</td>
								)}
							</Fragment>
						)}
					</Fragment>
				)}
			</Fragment>
		)
}
