import * as React from 'react';

const SvgWalletConnect = (props) => (
  <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12.5} r={12} fill="#fff" />
    <path
      d="m3 12.796 1.915-2.17 3.447 4.339 3.734-4.338 3.542 4.338 3.447-4.338L21 12.796 15.638 19.5l-3.542-4.338L8.362 19.5 3 12.796ZM8.362 10.035 6.638 7.866S8.266 5.5 12.096 5.5s5.649 2.366 5.649 2.366l-1.724 2.17c-.702-.658-2.393-1.775-3.925-1.775s-3.16 1.051-3.734 1.774Z"
      fill="#0073F0"
    />
  </svg>
);

export default SvgWalletConnect;
