import React from 'react'
import { Link } from 'react-router-dom'
import styles from './LogoNav.module.scss'
import { vbetLogo } from '../../assets/index'
import { AppBar, IconButton, Toolbar, useMediaQuery, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faHome, faUserPlus } from '@fortawesome/free-solid-svg-icons'

export const LogoNav: React.FC = () => {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down('md'))
	if (mobile)
		return (
			<AppBar position="static" color="transparent" style={{ boxShadow: "none" }}>
				<Toolbar>
					<img src={vbetLogo} alt='vbet-logo'></img>
					<div style={{ flexGrow: 1 }} />
					{
						Object.entries({
							'/': faHome,
							'/dashboard': faCoins,
							'/auth': faUserPlus
						}).map(([path, icon]) => (
							<IconButton key={path} component={Link} to={path} style={{ color: 'white' }}>
								<FontAwesomeIcon icon={icon} />
							</IconButton>
						))
					}
				</Toolbar>
			</AppBar>
		)
	return (
		<div className={styles.navContainer}>
			<Link to='/'>
				<img src={vbetLogo} alt='vbet-logo'></img>
			</Link>
			<ul>
				<Link to='/'>
					<li>Home</li>
				</Link>
				<Link to='/dashboard'>
					<li>Head-To-Head Bets</li>
				</Link>
				<Link to='/auth'>
					<li>Login</li>
				</Link>
				<Link to='/auth/register'>
					<li>Register Now</li>
				</Link>
			</ul>
		</div>
	)
}
