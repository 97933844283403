import { useCallback, useEffect, useState } from 'react'
import './RightCards.scss'
import { H2H } from '../../api/H2H'
import { useAppSelector } from '../../redux/hooks'
import { token } from '../../assets'
import { BetType } from '../../api/BetType'
import { Overwrite } from 'utility-types'
import { UserType } from '../../api/UserType'
import { CardHeader, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@material-ui/core'
import { WalletTransactions } from './components/WalletTransactions/WalletTransactions'
import { HtoHStatusEnum } from '../admin/HtoHStatusEnum'
import { HtoHWinnerEnum } from '../admin/HtoHWinnerEnum'
import { API } from '../../api/API'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const titles = {
	transactions: 'Transactions',
	reserved: 'Reserved Bets',
	created: 'Total Bets Created',
	open: 'Total Bets Open',
	won: 'Total Bets Won',
	lost: 'Total Bets Lost',
	drawn: 'Drawn Bets',
	deleted: 'Bets deleted by you',
}

const initialState = {
	transactions: 0,
	reserved: 0,
	created: 0,
	open: 0,
	won: 0,
	lost: 0,
	drawn: 0,
	deleted: 0,
}

type OverwrittenBet = Overwrite<BetType, { bettor_a: UserType; bettor_b: UserType | null }> & { created: boolean }

export default function RightCards() {
	const [descriptions, setDescriptions] = useState<{ description: string }[]>([])
	const [selected, setSelected] = useState<keyof typeof initialState>()
	const [state, setState] = useState(initialState)
	const [bets, setBets] = useState<OverwrittenBet[]>([])
	const update = useAppSelector((state) => state.userDataReducer.update)
	const [amount, setAmount] = useState({
		reserved: 0,
		won: 0,
		lost: 0,
		drawn: 0
	})
	const { push } = useHistory()

	const run = useCallback(async () => {
		const transactions = await API.getTransactions()
		const betsLocal = await H2H.getMyBetsAll()
		let tempState = { ...initialState, transactions: transactions.length }
		const amountLocal = {
			reserved: 0,
			won: 0,
			lost: 0,
			drawn: 0
		}		
		betsLocal.forEach((bet: any) => {
			if (bet.created) {
				tempState.created++
				// User created the bet.
				// If results declared.
				if (bet.status === HtoHStatusEnum.ResultsDeclared) {
					// If winner none.
					if (bet.winner === 0) {
						tempState.drawn++
						amountLocal.drawn+=(bet.amount / 1e18)
					}
					// If user was bettor A and winner in bettor A
					if (bet.winner === 1) {
						tempState.won++
						amountLocal.won += (bet.amount / 1e18)
						//
					}
					// User is bettor A but winner is bettor B
					else if (bet.winner === 2) {
						tempState.lost++
						amountLocal.lost += (bet.amount / 1e18)
					}
				}
			} else {
				if (bet.status === HtoHStatusEnum.ResultsDeclared) {
					// If winner none.
					if (bet.winner === 0) {
						tempState.drawn++
						amountLocal.drawn+=(bet.amount / 1e18)
					}
					// If user was bettor B but winner in bettor A
					if (bet.winner === 1) {
						tempState.lost++
						amountLocal.lost += (bet.amount / 1e18)
						//
					}
					// User is bettor B and winner is bettor B
					else if (bet.winner === 2) {
						tempState.won++
						amountLocal.won += (bet.amount / 1e18)
					}
				}
			}
			if (
				bet.status === HtoHStatusEnum.OpenForBetting ||
				bet.status === HtoHStatusEnum.ClosedForBetting ||
				bet.status === HtoHStatusEnum.WaitingApproval
			) {
				tempState.reserved++
			}
			if (bet.status === HtoHStatusEnum.Exited) {
				tempState.deleted++
			}
			if (
				bet.status !== HtoHStatusEnum.Exited &&
				bet.status !== HtoHStatusEnum.ClosedForBetting &&
				bet.status !== HtoHStatusEnum.ResultsDeclared &&
				!bet.bettor_b &&
				new Date(bet.end) > new Date()
			)
				tempState.open++
		})
		setBets(betsLocal)
		setDescriptions(transactions)
		setState(tempState)
		setAmount(amountLocal)
	}, [])

	useEffect(() => {
		run()
	}, [run, update])

	const select = (selection: string, value: number) => () => value && setSelected(selection as keyof typeof initialState)

	const deselect = () => setSelected(undefined)

	return (
		<>
			{Object.entries(state).map(([key, value]) => (
				<div
					className='RightCard'
					onClick={() => {
						if (key === 'transactions') {
							push('/dashboard/transactions')
						} else {
							select(key, value)()
						}
					}}
				>
					<div className='card'>
						<div className='inner'>
							<div className='outer'>
								<div className='bal'>
									<div className='num'>{value}</div>
									<div className='text'>{titles[key as keyof typeof titles]}
										{amount[key as keyof typeof amount] ? ` (${amount[key as keyof typeof amount]} VNLA)` : "" }
									</div>
								</div>
								<div className='img'>
									<img src={token} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
			{!!selected && (
				<Dialog open={!!selected} onClose={deselect}>
					{selected === 'transactions' ? (
						<>
							<DialogTitle style={{ textTransform: 'capitalize' }}>{selected}</DialogTitle>
							<DialogContent>
								{descriptions.map((v, i) => (
									<div key={i} style={{ lineHeight: 2 }}>
										{v.description}
									</div>
								))}
							</DialogContent>
						</>
					) : (
						<>
							<CardHeader
								title={`${selected} bets`}
								action={
									<IconButton onClick={deselect}>
										<FontAwesomeIcon icon={faTimes as any}/>
									</IconButton>
								}
								style={{ textTransform: 'capitalize' }}
							/>
							{/* <DialogTitle style={{ textTransform: 'capitalize' }}>{selected} bets</DialogTitle> */}
							<DialogContent>
								{bets
									.filter(
										(bet) =>
											({
												created: bet.created,
												open: bet.status !== HtoHStatusEnum.Exited && !bet.bettor_b && new Date(bet.end) > new Date(),
												lost:
													bet.status === HtoHStatusEnum.ResultsDeclared &&
													bet.winner !== 0 &&
													!((bet.created && bet.winner === 1) || (!bet.created && bet.winner === 2)),
												won:
													bet.status === HtoHStatusEnum.ResultsDeclared &&
													bet.winner !== 0 &&
													((bet.created && bet.winner === 1) || (!bet.created && bet.winner === 2)),
												reserved:
													bet.status === HtoHStatusEnum.OpenForBetting ||
													bet.status === HtoHStatusEnum.ClosedForBetting ||
													bet.status === HtoHStatusEnum.WaitingApproval,
												drawn: bet.status === HtoHStatusEnum.ResultsDeclared && bet.winner === HtoHWinnerEnum.None,
												deleted: bet.status === HtoHStatusEnum.Exited,
											}[selected])
									)
									.map((v, i, a) => (
										<>
											<WalletTransactions
												key={i}
												eth={v.name}
												vnla={`${v.amount}`}
												id={v}
												status={v.status}
												winner={v.winner}
												approved={v.approved}
												tag={
													v.status === HtoHStatusEnum.ResultsDeclared ||
													v.bettor_b ||
													new Date(v.end) < new Date() ||
													v.status === HtoHStatusEnum.Exited
													? 'CLOSED' : 'OPEN'
												}
												type={0}
											/>
											{i !== a.length - 1 && (
												<Divider
													key={i}
													style={{
														margin: '1rem 0rem',
														height: '2px',
														background: `linear-gradient(100.46deg, #e20070 11.04%, #33008d 57.79%)`,
													}}
												/>
											)}
										</>
									))}
							</DialogContent>
						</>
					)}
				</Dialog>
			)}
		</>
	)
}
