import axios from 'axios'
import { toast } from 'react-toastify'
import { UserType } from './UserType'
import { WalletType } from './WalletType'

const API_LINK = process.env.REACT_APP_API_LINK || 'http://localhost:8080/' // api for default

export const resolve_url = (link: string) => {
	return new URL(link, API_LINK).href
}

export const get_static_url = (image: string | null) => {
	return image ? resolve_url(`static/${image}`) : ''
}

export class API {
	static async verify_email(token: string) {
		try {
			return (
				await axios.get<{
					email: string
					ethereum_address: string
					verified?: boolean
				}>(resolve_url(`user/verify_email?token=${token}`))
			).data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('Error-ed')
		}
	}

	static async getDetails() {
		try {
			return (
				await axios.post<{
					email: string
					ethereum_address: string
					verified?: boolean
				}>(
					resolve_url(`user/getDetails`),
					{ uid: localStorage.getItem('uid') },
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('Error-ed')
		}
	}
	static async update_admin(body: { email: string; enabled: boolean; wallet: string }) {
		try {
			return (
				await axios.post<{ description: string }[]>(
					resolve_url(`user/update_admin`),
					{ ...body },
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (err: any) {
			// if (err.response) {
			// 	console.error(err.response?.data)
			// 	console.error(err.response.status)
			// 	// if (err.response?.data?.message) {
			// 		// toast.error(err.response?.data?.message)
			// 	// }
			// }
			throw new Error(err.response?.data?.message || 'Error-ed')
			// return []
		}
	}
	static async getTransactions() {
		try {
			return (
				await axios.post<{ description: string }[]>(
					resolve_url(`user/getTransactions`),
					{ uid: localStorage.getItem('uid') },
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('Error-ed')
			return []
		}
	}
	static async emails() {
		try {
			return (
				await axios.get<{ email: string }[]>(
					resolve_url(`user/getEmails`),

					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('Error-ed')
			return []
		}
	}
	// getTransactionsByEmail
	static async getTransactionsByEmail(email: string) {
		try {
			return (
				await axios.post<{ description: string; createdAt: string }[]>(
					resolve_url(`user/getTransactionsByEmail`),
					{
						email,
					},
					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('Error-ed')
			return []
		}
	}
	static async getTransactionsAdmin() {
		try {
			return (
				await axios.get<{ description: string; createdAt: string }[]>(
					resolve_url(`user/getTransactionsAdmin`),

					{
						headers: {
							Authorization: localStorage.getItem('token'),
						},
					}
				)
			).data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('Error-ed')
			return []
		}
	}

	static async balances() {
		try {
			return await axios.post<WalletType>(
				resolve_url(`user/balances`),
				{ uid: localStorage.getItem('uid') },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			throw new Error('Error-ed')
		}
	}

	static async approve_withdrawal(uid: string) {
		try {
			return await axios.post<WalletType>(
				resolve_url(`user/approve_withdrawable`),
				{ uid },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			throw new Error('Error-ed')
		}
	}

	static async add_withdrawable(amount: string) {
		try {
			const wallet = await axios.post<WalletType>(
				resolve_url(`user/add_withdrawable`),
				{ uid: localStorage.getItem('uid'), amount },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			return wallet.data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			throw new Error('add_withdrawable: Error-ed')
		}
	}

	static async direct_withdraw(amount: string, txn: string) {
		try {
			const wallet = await axios.post<WalletType>(
				resolve_url(`user/direct_withdraw`),
				{ uid: localStorage.getItem('uid'), amount, txnHash: txn },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			return wallet
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			throw new Error('add_withdrawable: Error-ed')
		}
	}

	static async withdraw(amount: string, txnHash: string) {
		try {
			const wallet = await axios.post<WalletType>(
				resolve_url(`user/withdraw`),
				{ uid: localStorage.getItem('uid'), amount, txnHash },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			// toast.success('Withdrawn')
			return wallet.data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			throw new Error('deposit: Error-ed')
		}
	}

	static async withdraw_admin(email: string) {
		try {
			const wallet = await axios.post<WalletType>(
				resolve_url(`user/withdraw_admin`),
				{ email },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			toast.success('Set ZERO')
			return wallet.data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('deposit: Error-ed')
		}
	}

	static async deposit(txnHash: string, amount: string) {
		try {
			const wallet = await axios.post<WalletType>(
				resolve_url(`user/deposit`),
				{ uid: localStorage.getItem('uid'), amount, txnHash },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			toast.success('Deposited')
			return wallet.data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			throw new Error('deposit: Error-ed')
		}
	}

	static async deposit_admin(body: { txnHash: string; amount: string; ethereum_address: string }) {
		try {
			const wallet = await axios.post<WalletType>(
				resolve_url(`user/deposit_admin`),
				{ uid: localStorage.getItem('uid'), ...body },
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			toast.success('Deposited')
			return wallet.data
		} catch (err: any) {
			if (err.response) {
				console.error(err.response?.data)
				console.error(err.response.status)
				if (err.response?.data?.message) {
					toast.error(err.response?.data?.message)
				}
			}
			console.error(err)
			// throw new Error('deposit: Error-ed')
		}
	}

	static async verify({ uid, token }: { uid: string; token: string }) {
		try {
			const data = await axios.get(resolve_url(`user/verify/${uid}`), {
				headers: {
					Authorization: token,
				},
			})
			if (data.status === 200) {
				return true
			} else throw new Error('Not verified')
		} catch (err: any) {
			localStorage.clear()
		}
	}

	static async get_all() {
		return (
			await axios.get<UserType[]>(resolve_url('user/all'), {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
		).data
	}

	static async get_all_users() {
		try {
			return (
				await axios.get<string[]>(resolve_url('user/all_users'), {
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				})
			).data
		} catch (error) {
			API.handleError(error, "Couldn't get users")
		}
	}

	static async all_bets() {
		return (await axios.get(resolve_url('all_bets'))).data
	}

	static async register(data: any) {
		try {
			return await axios.post(resolve_url('user/signup'), data, {
				headers: {
					'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				},
			})
		} catch (err: any) {
			console.error('response', err?.response?.data?.failedOperation)
			API.handleError(err, 'Failed to register')
		}
	}

	static async open_bets() {
		return (await axios.get(resolve_url('get_available_bets'))).data
	}

	static async my_bets(uid: string) {
		return (await axios.get(resolve_url(`my_bets/${uid}`))).data
	}

	static async bet_details(uid: string) {
		return (await axios.get(resolve_url(`bet_details/${uid}`))).data
	}

	static async bet(
		{
			tx,
			bet_amount,
			uid,
			bet_counter,
			bet_name,
			bet_instance_counter,
		}: {
			tx: string
			bet_amount: string
			uid: string
			bet_name: string
			bet_counter: string
			bet_instance_counter: string
		},
		token: string
	) {
		try {
			return await axios.post(
				resolve_url('bet'),
				{
					tx,
					bet_amount,
					uid,
					bet_counter,
					bet_instance_counter,
					bet_name,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
		} catch (err: any) {
			API.handleError(err)
		}
	}

	static async bets() {
		try {
			const return_data =
				(
					await axios.get<
						Array<{
							uid: string
							betName: string
							bet_counter: number
							end: string
							start: string
							tx: string
						}>
					>(resolve_url('bets'))
				).data.map((elem) => ({ ...elem, end: new Date(elem.end), start: new Date(elem.end) })) ?? []
			return return_data
		} catch (err: any) {
			console.error(err)
			if (err.response) {
				console.error(err?.resposnse?.data)
				console.error(err?.resposnse?.status)
			}
		}
		return []
	}

	static async create_a_bet({
		body,
		token,
	}: {
		body: { uid: string; betName: string; bet_counter: string; end: number; start: number; tx: string }
		token: string
	}) {
		try {
			return await axios.post(resolve_url('create_bet'), body, {
				headers: {
					Authorization: token,
				},
			})
		} catch (err: any) {
			API.handleError(err, 'Failed to update backend.')
		}
	}

	static async bet_against(body: { bet_uid: string; uid: string; tx: string }, token: string) {
		try {
			return await axios.post(resolve_url('bet_against'), body, {
				headers: {
					Authorization: token,
				},
			})
		} catch (err: any) {
			API.handleError(err)
		}
	}

	static async login({ email, password }: { email: string; password: string }) {
		try {
			return await axios.post<{
				uid: string
				token: string
				profile: string
				admin: boolean
			}>(resolve_url('user/login/'), {
				email,
				password,
			})
		} catch (err: any) {
			API.handleError(err, 'Failed to Login')
		}
	}

	static async forgot(email: string) {
		try {
			await axios.post(resolve_url('user/forgot'), { email })
		} catch (err: any) {
			API.handleError(err, `Couldn't find wallet address`)
		}
	}

	static async reset_password(password: string, token: string) {
		try {
			await axios.post(
				resolve_url('user/reset_password'),
				{ password },
				{
					headers: {
						Authorization: token,
					},
				}
			)
		} catch (err: any) {
			API.handleError(err, `Couldn't reset password`)
		}
	}

	static async update_avatar(file: File) {
		try {
			let formData = new FormData()
			formData.append('file', file)
			const { data } = await axios.put<string>(resolve_url('user/update_avatar'), formData, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			localStorage.setItem('profile', data)
			return data
		} catch (err: any) {
			API.handleError(err, `Couldn't update avatar`)
		}
		return ''
	}

	static async support_form(email: string, description: string) {
		try {
			return await axios.post(resolve_url('support/ticket'), { email, description })
		} catch (err: any) {
			API.handleError(err, `Couldn't send suppport request.`)
		}
	}

	private static handleError(err: any, msg?: string) {
		console.error(err)

		if (err.response) {
			console.log({ err: err })
			console.error(err?.response?.status)
		}
		toast.info(err?.response?.data?.message ?? err?.response?.data?.failedOperation ?? msg ?? 'Error-ed')
		throw new Error(err?.response?.data?.message ?? err?.response?.data?.failedOperation ?? msg ?? 'Error-ed')
	}
}
