// import { Card, CardContent, CardHeader } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router'
import { BetType } from '../../api/BetType'
import { H2H } from '../../api/H2H'
import { WalletTransactions } from './components/WalletTransactions/WalletTransactions'
import { HtoHStatusEnum } from '../admin/HtoHStatusEnum'

export default function Shared() {
	const { id } = useParams<{ id: string }>()
	const [betData, setBetData] = React.useState<BetType>()

	React.useEffect(() => {
		const run = async () => {
			if (id) {
				setBetData(((await H2H.getBet(id)) as any)?.data ?? {})
			}
		}
		run()
	}, [id])

	if (betData)
		return (
			<WalletTransactions
				eth={`${betData.name}`}
				vnla={`${betData.amount}`}
				id={betData as any}
				status={betData.status}
				winner={betData.winner}
				approved={betData.approved}
				tag={betData.status === HtoHStatusEnum.ResultsDeclared ||
					betData.betted ||
					(betData?.bettor_b && !betData.private) ||
					new Date(betData.end) < new Date() ||
					betData.status === HtoHStatusEnum.Exited
					? 'CLOSED'
					: 'OPEN'}
				type={0}
			/>
		)
	return <></>
}
