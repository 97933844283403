import { AppBar, BottomNavigation, BottomNavigationAction, BottomNavigationProps, Hidden, makeStyles, Toolbar } from "@material-ui/core";
import { customerSupport, token, token_heap, wallet } from '../../assets'
import { useAppSelector } from "../../redux/hooks";

const useStyles = makeStyles(_theme => ({
    appbar: {
        top: 'auto',
        bottom: 0,
    },
    bottomNav: { flex: 1}
}))

export default function Footer(props: BottomNavigationProps) {
    const classes = useStyles()
    const { loggedIn } = useAppSelector(state => state.userLoginReducer)
    return(
        <Hidden mdUp>
            <Toolbar/>
            <AppBar position="fixed" color="inherit" className={classes.appbar}>
                <Toolbar>
                <BottomNavigation
                    className={classes.bottomNav}
                    showLabels
                    {...props}
                >
                    <BottomNavigationAction label="Create" icon={<img src={token} alt="Create" width="24" height="24"/>}/>
                    <BottomNavigationAction label="Bets" icon={<img src={token_heap} alt="Bets" width="24" height="24"/>}/>
                    {
                        loggedIn &&
                        <BottomNavigationAction label="Transactions" icon={<img src={wallet} alt="Wallets" width="24" height="24"/>}/>
                    }
                    <BottomNavigationAction label="Support" icon={<img src={customerSupport} alt="Support" width="24" height="24"/>}/>
                </BottomNavigation>
                </Toolbar>

            </AppBar>
        </Hidden>
    )
}