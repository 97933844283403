import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

interface TabPanelProps {
	children?: React.ReactNode
	index: any
	value: any
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index: any) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	}
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: 'flex',
		minHeight: '100vh',
		width: '100%',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		minWidth: 'fit-content',
	},
	tab: {
		padding: '0 1rem',
	},
	tabpanel: {
		width: '100%',
	},
}))

export function VerticalTabs(args: { items: { label: string; child: React.ReactNode }[] }) {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}
	const { items } = args
	return (
		<div className={classes.root}>
			<Tabs
				orientation='vertical'
				variant='scrollable'
				value={value}
				onChange={handleChange}
				aria-label='Vertical tabs example'
				className={classes.tabs}
			>
				{items.map((elem, idx) => {
					return <Tab label={elem.label} {...a11yProps(idx)} />
				})}
			</Tabs>
			<div className={classes.tabpanel}>
				{items.map((elem, idx) => {
					return (
						<TabPanel value={value} index={idx}>
							<div className={classes.tabpanel}>{elem.child}</div>
						</TabPanel>
					)
				})}
			</div>
		</div>
	)
}
