import React from 'react'

import './App.scss'
import { MetaMaskHook } from './hooks/MetaMaskHook'
import { useAppDispatch } from './redux/hooks'
import { UserDataActions } from './redux/slices/UserData'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import { MetaMaskHook } from './hooks/MetaMaskHook'
// import { useAppDispatch, useAppSelector } from './redux/hooks'
// import { UserDataActions } from './redux/slices/UserData'
// import { getAccounts } from './utilities/getWeb3'
import {
	// LoadContracts,
	Routes,
} from './Routes'

function App() {
	const { accounts, network } = MetaMaskHook()

	// const primaryAccount = useAppSelector((state) => state.userDataReducer.primary)
	const dispatch = useAppDispatch()

	React.useEffect(() => {
		if (accounts[0]) {
			dispatch(UserDataActions.setPrimaryAccount(accounts[0]))
		}
		if(network !== undefined) {
			dispatch(UserDataActions.setNetwork(network))
		}
	}, [accounts, network ,dispatch])

	// React.useEffect(() => {
	// 	if (primaryAccount) {
	// 		console.log(primaryAccount)
	// 	}
	// }, [primaryAccount])

	return (
		<>
			<div style={{ maxWidth: '100vw' }} className='App'>
				<ToastContainer></ToastContainer>
				<Routes></Routes>
			</div>
		</>
	)
}

export default App
