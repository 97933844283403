import React from 'react'

import { getAccounts, getNetworkId } from '../utilities/getWeb3'

/**
 * Hook for Metamask connection.
 */
export const MetaMaskHook = () => {
	const [accounts, setAccounts] = React.useState<string[]>([])
	const [network, setNetwork] = React.useState<number>()

	async function run() {
		try {
			setAccounts(await getAccounts({ biconomy: false }))
			setNetwork(await getNetworkId({ biconomy: false }))
			// setAccounts(await getAccounts({ biconomy: false }))
			window?.ethereum.on('accountsChanged', async function () {
				setAccounts(await getAccounts({ biconomy: false }))
				// console.log(await getAccounts({ biconomy: false }))
			})
			window?.ethereum.on('networkChanged', async function () {
				setNetwork(await getNetworkId({ biconomy: false }))
				// console.log(await getNetworkId({ biconomy: false }))
			})
		} catch (err: any) {
			// console.error(err)
			if (err.code) {
				if (err.code === -32002) {
					alert('Pending approval')
				}
				if (err.code === 4001) {
					alert('Please connect wallet to continue')
				}
			}
		}
	}

	React.useEffect(() => {
		run()
	}, [])

	return { accounts, setAccounts, network, setNetwork, run }
}
