import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Container from 'typedi'

import { API } from '../../api/API'
import { H2H } from '../../api/H2H'
import { token_heap, wallet } from '../../assets'
import { TreasuryAddress } from '../../config'

import { useAppSelector } from '../../redux/hooks'
import { UserDataActions } from '../../redux/slices/UserData'
import { ContractsInstance } from '../../utilities/getContractInstance'
// import { LoadContracts } from '../../Routes'
// import { ContractsInstance } from '../../utilities/getContractInstance'

import { BN } from '../../utilities/BN'
import './RightCards.scss'
import { Deposit, Withdraw } from './Deposit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
// import { ERC20 } from '../../types/ERC20'

interface WalletDialogProps {
	open: boolean
	toggleOpen: () => void
	setBalance: React.Dispatch<React.SetStateAction<string>>
}
const contracts = Container.get(ContractsInstance)

export default function Wallet({ open, toggleOpen, setBalance }: WalletDialogProps) {
	const [state, setState] = useState({
		balance: '0',
		bets: 0,
		amount: '0',
		withdrawable: '0',
		unWithdrawable: '0',
		reserved: '0',
		max: 0,
	})

	const update = useAppSelector((state) => state.userDataReducer.update)

	const run = React.useCallback(async () => {
		const resp = (await API.balances()).data
		const bets = await H2H.getMyBetsAll()
		const spendableBalance = new BN(resp.totalBalance)
			.minus(new BN(resp.reservedBalance))
			.minus(new BN(resp.unApprovedWithdrawableBalance))
			.minus(new BN(resp.withdrawableBalance))

		// console.log({ spendableBalance: spendableBalance.toString() })
		setState((state) => ({
			...state,
			balance: spendableBalance.toString(),
			amount: new BN(resp.totalBalance).toString(),
			reserved: new BN(resp.reservedBalance).toString(),
			withdrawable: new BN(resp.withdrawableBalance).toString(),
			unWithdrawable: new BN(resp.unApprovedWithdrawableBalance).toString(),
			bets: bets.filter((elem) => elem.created === true).length,
		}))
		setBalance(spendableBalance.dividedBy('1e18').toFormat(2))
	}, [setBalance])

	React.useEffect(() => {
		run()
	}, [run, update, open, setBalance])
	// const { setAccounts } = MetaMaskHook()
	// const primaryAccount = useAppSelector((state) => state.userDataReducer.primary)
	// const contracts_loaded = useAppSelector((state) => state.userDataReducer.contracts_loaded)

	// const connectWallet = async () => {
	// 	setAccounts(await getAccounts({ biconomy: false }))
	// }

	// React.useEffect(() => {
	// 	// if (primaryAccount && contracts_loaded) {
	// 	// 	Container.get(ContractsInstance)
	// 	// 		.get_token()
	// 	// 		.methods.balanceOf(primaryAccount)
	// 	// 		.call()
	// 	// 		.then((data) => {
	// 	// 			setState((state) => ({
	// 	// 				...state,
	// 	// 				balance: new bignum(data?.toString())?.dividedToIntegerBy(Math.pow(10, 18))?.toString(),
	// 	// 			}))
	// 	// 		})
	// 	// }
	// }, [primaryAccount, contracts_loaded])
	let _token = () => {
		return Container.get(ContractsInstance).get_token()
	}

	// React.useEffect(() => {
	// 	// API.my_bets(localStorage.getItem('uid') ?? '')
	// 	// 	.then((data) => {
	// 	// 		setState((state) => ({
	// 	// 			...state,
	// 	// 			bets: data?.length ?? 0,
	// 	// 			amount: data?.reduce(
	// 	// 				(a: number, b: any) => a + parseInt(new bignum(b.bet_amount).dividedToIntegerBy(Math.pow(10, 18)).toString()),
	// 	// 				0
	// 	// 			),
	// 	// 		}))
	// 	// 	})
	// 	// 	.catch((err) => {
	// 	// 		console.error(err)
	// 	// 	})

	// }, [update])

	const dispatch = useDispatch()
	let primary = useAppSelector((state) => state.userDataReducer.primary)
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down('sm'))
	const [alerting, setAlerting] = useState(false)

	return (
		<Dialog open={open} onClose={toggleOpen} fullWidth={true} maxWidth='sm' fullScreen={mobile}>
			<DialogTitle disableTypography>
				<Typography align='right'>
					<IconButton onClick={toggleOpen}>
						<FontAwesomeIcon icon={faTimes} />
					</IconButton>
				</Typography>
			</DialogTitle>
			<DialogContent>
				<div className='' style={{ marginBottom: '1rem' }}>
					<div className='splitter header'>Deposit VNLA</div>
					<div className='card'>
						<div className='inner'>
							{<Deposit />}

							{/* <BridgeCard /> */}
						</div>
					</div>
					{/* {FeaturedBet()} */}
				</div>

				<div className=''>
					<div className='splitter header'>Withdraw Request VNLA</div>

					<div className='card'>
						<div className='inner'>
							{<Withdraw max_amount={new BN(state.balance).dividedBy('1e18').toString()} />}

							{/* <BridgeCard /> */}
						</div>
					</div>
					{/* {FeaturedBet()} */}
				</div>
				{/* <LoadContracts></LoadContracts> */}

				<div className='RightCard WalletBalance'>
					<div
						className='card'
						onClick={() => {
							// if (!primaryAccount) {
							// 	connectWallet()
							// }
							dispatch(UserDataActions.setOption('transactions'))
						}}
					>
						<div className='inner'>
							<div className='outer'>
								<div className='bal'>
									<div className='num'>{new BN(state.balance).dividedBy('1e18').toFormat(2)} VNLA</div>
									<div className='text'>Wallet Balance</div>
								</div>
								<div className='img'>
									<img src={wallet} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='RightCard'>
					<div className='card'>
						<div className='inner'>
							<div className='outer'>
								<div className='bal'>
									<div className='num'>{new BN(state.reserved).dividedBy('1e18').toFormat(2)} VNLA</div>
									<div className='text'>Reserved in Bets</div>
								</div>
								<div className='img'>
									<img src={token_heap} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='RightCard'>
					<div
						className='card'
						onClick={async () => {
							setAlerting(true)
							if (alerting) {
								alert('Transaction already midway? DO NOT CLICK TWICE')
								return
							}
							alert('Please stay on page until withdraw completes.')
							try {
								const treasury = contracts.get_treasury()
								treasury.methods.withdraw(state.withdrawable.toString()).estimateGas({
									from: primary,
								})
								// _token().methods.transferFrom(TreasuryAddress, primary, state.withdrawable.toString()).estimateGas({
								// 	from: primary,
								// })
								const tx = await treasury.methods.withdraw(state.withdrawable.toString()).send({
									from: primary,
								})
								await API.withdraw(state.withdrawable, tx.transactionHash)
								toast.success(`Withdrawal of ${new BN(state.withdrawable).dividedBy('1e18').toFormat(2)} VNLA successful`)
								dispatch(UserDataActions.setUpdate())
							} catch (err) {
								console.error(err)
							}
							setAlerting(false)
						}}
					>
						<div className='inner'>
							<div className='outer'>
								<div className='bal withdraw'>
									<div className='num box'>{new BN(state.withdrawable).dividedBy('1e18').toFormat(2)} VNLA</div>
									<div
										className='box'
										style={{
											display: 'flex',
											alignItems: 'center',
											boxSizing: 'border-box',
										}}
									>
										<button className='bttn' disabled={alerting}>WITHDRAW</button>
									</div>
									<div className='text box'>Withdrawable Balance</div>
								</div>
								<div className='img'>
									<img src={token_heap} alt='' />
								</div>
							</div>
						</div>
					</div>
					{alerting && (
						<div style={{ textAlign: 'center', maxWidth: '80%', margin: '0 auto' }} className='red'>
							<p style={{ color: 'red' }}>Please stay on page until withdraw completes!!! DO NOT REFRESH or EXIT</p>
						</div>
					)}
				</div>
				<div className='RightCard'>
					<div className='card'>
						<div className='inner'>
							<div className='outer'>
								<div className='bal'>
									<div className='num'>{new BN(state.unWithdrawable).dividedBy('1e18').toFormat(2)} VNLA</div>
									<div className='text'>Pending Withdrawal Request</div>
								</div>
								<div className='img'>
									<img src={token_heap} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='RightCard WalletBalance'>
					<div
						className='card'
						onClick={() => {
							// if (!primaryAccount) {
							// 	connectWallet()
							// }
							dispatch(UserDataActions.setOption('transactions'))
						}}
					>
						<div className='inner'>
							<div className='outer'>
								<div className='bal'>
									<div className='num'>{new BN(state.amount).dividedBy('1e18').toFormat(2)} VNLA</div>
									<div className='text'>Total Balance</div>
								</div>
								<div className='img'>
									<img src={token_heap} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	)
}
