import React, { Fragment } from 'react'
import { useAppSelector } from '../../redux/hooks'
import { API } from '../../api/API'
import SelectSearch, { fuzzySearch } from 'react-select-search'
import './Search.css'
export const TransactionListByUser: React.FC = () => {
	const [descriptions, setDescriptions] = React.useState<{ description: string; createdAt: string }[]>([])
	const update = useAppSelector((state) => state.userDataReducer.update)
	const [email, setEmail] = React.useState('')
	const [emails, setEmails] = React.useState<{ email: string }[]>([])
	React.useEffect(() => {
		const run = async () => {
			const emails = await API.emails()
			// console.log({ emails })
			setEmails(emails)
		}
		run()
	}, [update])

	React.useEffect(() => {
		const run = async () => {
			if (email) {
				setDescriptions(await API.getTransactionsByEmail(email))
			} else {
				setDescriptions([])
			}
		}
		run()
	}, [email])
	return (
		<>
			{descriptions && <h1>Transactions</h1>}
			<SelectSearch
				search
				emptyMessage='Not found'
				options={emails.map((elem) => ({
					name: elem.email,
					value: elem.email,
				}))}
				value=''
				filterOptions={(options) => {
					const filter = fuzzySearch(options)

					return (q) => filter(q).slice(0, 8)
				}}
				onChange={(e) => setEmail(e as any)}
				placeholder='Select an Email'
			></SelectSearch>
			<select name='email' value={email} onChange={(e) => setEmail(e.target.value)} id=''>
				<option value={''}>{'Select'}</option>

				{emails &&
					emails.map((elem) => {
						return (
							<>
								<option value={elem.email}>{elem.email}</option>
							</>
						)
					})}
			</select>
			{descriptions.map((elem) => (
				<Fragment>
					<div style={{ lineHeight: 2 }} className=''>
						{new Date(elem.createdAt).toLocaleString()} - {elem.description}
					</div>
				</Fragment>
			))}
		</>
	)
}
