import { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { API } from '../../api/API'
import { AuthBtn } from '../../components/AuthBtn/AuthBtn'
import { Input } from '../../components/Input/Input'
import { LogoNav } from '../../components/LogoNav/LogoNav'
import './Auth.scss'

export default function Forgot() {
    const [creds, setCreds] = useState<{ password: string, confirm: string }>({ password: '', confirm: '' })
    const { token } = useParams<{ token: string }>()
    const history = useHistory()

    const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCreds({ ...creds, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (creds.password === creds.confirm) {
            API.reset_password(creds.password, token)
                .then(() => toast.success('Password reset'))
                .catch(() => {})
            history.push('/auth')
        } else {
            toast.error('Passwords do not match')
        }
    }
    return(
        <div className='AUTH'>
			<div className='authContainer'>
				<LogoNav />
				<div className='componentContainer'>
                    <p style={{ textAlign: 'center', color: 'white', padding: '15px 0' }}>
                        Reset password
                    </p>
                    <form method='post' onSubmit={handleSubmit}>
                        <Input
                            name='New Password'
                            placeHolder='New Password'
                            value={creds.password}
                            type='password'
                            required
                            tag='password'
                            valueHandler={handleValue}
                        />
                        <Input
                            name='Confirm Password'
                            placeHolder='Confirm Password'
                            value={creds.confirm}
                            type='password'
                            required
                            tag='confirm'
                            valueHandler={handleValue}
                        />
                        <AuthBtn clickHandler={()=>{}}>Reset</AuthBtn>
                    </form>
                </div>
            </div>
        </div>
    )
}