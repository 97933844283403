import React from 'react'
import './Button.scss'

export const Button: React.FC<{ clickHandler?: () => any }> = ({ children, clickHandler }) => (
	<button
		onClick={() => {
			if (clickHandler) clickHandler()
		}}
		id='custom-button'
	>
		{children}
	</button>
)
