import { Button, Dialog, Hidden } from '@material-ui/core'
import React, { Fragment } from 'react'
import { toast } from 'react-toastify'
import { resolve_url } from '../../api/API'
import Support from '../../pages/Support'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setMessage } from '../../redux/slices/Notification'
import './Main.scss'

export const Main: React.FC<{
	Left: React.ReactNode
	Right: React.ReactNode
	Center: React.ReactNode,
	tab: number
}> = ({ Left, Right, Center, tab }) => {
	const [open, setOpen] = React.useState(false)
	const [listening, setListening] = React.useState(false)
	const { uid } = useAppSelector(state => state.userLoginReducer)
	const dispatch = useAppDispatch()
	React.useEffect(() => {
		let events: EventSource
		if (!listening && uid) {
			const url = resolve_url(`sse/${uid}`)
			events = new EventSource(url)
			// events.onopen = () => console.log('SSE OPEN', uid)
			// events.onerror = () => console.log('SSE ERROR', uid)
			events.onmessage = (e) => {
				// console.log("SSE DATA", e.data)
				if (!!e.data && !e.data.includes('KEEP ALIVE')) {	
					dispatch(setMessage(e.data))
					toast.info(e.data)
				}
			}
			setListening(true)
			// return () => {
			// 	events?.close()
			// }
		}
	},[listening, dispatch, uid])

	const toggleOpen = () => setOpen(o => !o)
	return (
		<Fragment>
			<div id='Main' className=''>
				<div className='Base'>
					<Hidden smDown>
						<div className='Context Left'>
							{Left}
							<Button 
								fullWidth 
								variant='contained' 
								color='primary' 
								onClick={toggleOpen} 
								className='bttn' 
								size='small'
								style={{ marginTop: '1rem' }}
							>
								Support
							</Button>
						</div>
						<div className='Context Center'>{Center}</div>
						<div className='Context Right'> {Right}</div>
					</Hidden>
					<Hidden mdUp>
						{[Left, Center, Right, <Support/>][tab]}
					</Hidden>
				</div>
			</div>
			<Dialog open={open} onClose={toggleOpen}>
				<Support closeButton handleClose={toggleOpen}/>
			</Dialog>
		</Fragment>
	)
}
