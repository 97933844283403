/* eslint-disable */
import React, { Fragment, useCallback, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'

import { Main } from '../../layouts/Main/Main'
import { BG } from './components/BG/BG'
import { MSC } from './components/MetamaskSucess/MetamaskSucess'
import { QRCodes } from './components/QRCodes/QRCodes'
import { SuccessBG } from './components/SuccessBG/SuccessBG'
import { CreateNewBet } from './components/CreateNewBet/CreateNewBet'
import './Home.scss'
import RightCards from './RightCards'
import { API } from '../../api/API'
import Container from 'typedi'
import { ContractsInstance } from '../../utilities/getContractInstance'
import { useAppSelector } from '../../redux/hooks'
import { TreasuryAddress } from '../../config'
import { useDispatch } from 'react-redux'
import { UserDataActions } from '../../redux/slices/UserData'
import { Nav } from '../../components/Nav/Nav'
import { RecentBets } from './components/RecentBets/RecentBets'
import { face2 } from '../../assets'
import { BetsListing } from './BetsListing'
import { Deposit, Withdraw } from './Deposit'
import { BetType } from '../../api/BetType'
import { H2H } from '../../api/H2H'
import { WalletTransactions } from './components/WalletTransactions/WalletTransactions'
import Footer from './Footer'
import Shared from './Shared'
import { login, setUID } from '../../redux/slices/UserLogin'
import { CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText } from '@material-ui/core'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

// import { BridgeCard } from './components/BridgeCard/BridgeCard'

const queryString = require('query-string')
type bg = 'successBG' | 'msc' | 'qrc' | 'bg'
export const bignum = (window as any).BigNumber

const RecentBetsHome: React.FC = () => {
	const [bets, setBets] = useState<Array<BetType>>([])

	React.useEffect(() => {
		// API.open_bets()
		// 	.then((data) => {
		// 		setBets(data)
		// 		console.log(data, 'bets')
		// 	})
		// 	.catch((err) => {
		// 		console.error(err)
		// 	})
	}, [])
	return (
		<div className='card'>
			<div className='inner'>
				{bets.map((elem) => (
					<span
						onClick={() => {
							// console.log(elem)
						}}
					>
						<RecentBets
							uid={elem?.uid}
							betName={elem?.name}
							fOpp={elem?.bettor_a}
							sOpp={elem?.bettor_b ?? ''}
							bet_amount={new bignum(elem.amount).dividedToIntegerBy(Math.pow(10, 18)).toString()}
							fOppImg={elem?.bettor_a ?? face2}
							sOppImg={elem?.bettor_b ?? ''}
							data=''
							exp={new Date(elem.end).toLocaleString()}
							status='LIVE'
							admin
						></RecentBets>
					</span>
				))}
			</div>
		</div>
	)
}

export const Home: React.FC<RouteComponentProps & { shared?: boolean; transactions?: boolean }> = (props) => {
	const { history, location, match, shared, transactions } = props
	const [showBG, setShowBG] = useState<bg>('bg')

	const [{ error_ed, msg, title }, setIncorrect] = useState({ error_ed: false, msg: '', title: '' })
	// const [msg, setIncorrect] = useState(false)
	const dispatch = useDispatch()

	const [bet, setBet] = useState<string>()
	const [betData, setBetData] = useState<BetType>()
	const [tab, setTab] = useState(0)
	const [formData, setFormData] = useState<{
		title: string
		budget: string
		date: string
		bet_type: string
		match: string
		bet_a: boolean
	}>({
		title: '',
		budget: '',
		date: '',
		bet_type: '',
		match: '',
		bet_a: true,
	})
	const { loggedIn } = useAppSelector((state) => state.userLoginReducer)
	const update = useAppSelector((state) => state.userDataReducer.update)
	const [descriptions, setDescriptions] = useState<{ description: string }[]>([])
	const setBG = useCallback((name: bg) => {
		setShowBG(name)
	}, [])

	React.useEffect(() => {
		const bet_id = queryString.parse(location.search).bet_id
		if (bet_id) {
			setBet(bet_id)
		}
	}, [location, match])

	React.useEffect(() => {
		const run = async () => {
			if (bet) {
				setBetData(((await H2H.getBet(bet)) as any)?.data ?? {})
			}
			// console.log(await API.getTransactions())
		}

		run()
	}, [bet])

	const setFormDataHandler = useCallback((e) => {
		setFormData((formdata) => ({
			...formdata,
			[e.target.name]: e.target.value,
		}))
	}, [])

	const returnCurrBG = useCallback(
		(name: bg) => {
			switch (name) {
				case 'bg':
					return <BG></BG>
				case 'qrc':
					return <QRCodes clickHandler={setBG}></QRCodes>
				case 'msc':
					return <MSC></MSC>
				case 'successBG':
					return <SuccessBG data={formData} clickHandler={setBG}></SuccessBG>
				default:
					return
			}
		},
		[setBG, formData]
	)

	React.useEffect(() => {
		if (shared) {
			setTab(1)
		}
	}, [shared])

	React.useEffect(() => {
		const uid = localStorage.getItem('uid')
		const token = localStorage.getItem('token')
		if (uid && token) {
			API.verify({ uid, token })
				.then((v) => {
					dispatch(login(!!v))
					dispatch(setUID(uid))
				})
				.catch(() => dispatch(login(false)))
		} else {
			dispatch(login(false))
			// history.push('/')
		}
	}, [history])

	React.useEffect(() => {
		if (transactions) {
			setTab(1)
		}
		if (loggedIn) {
			const run = async () => {
				const transactions = await API.getTransactions()
				setDescriptions(transactions)
			}
			run()
		}
	}, [loggedIn, update, transactions])

	const { primary, network } = useAppSelector((state) => state.userDataReducer)

	React.useEffect(() => {
		if (loggedIn) {
			const run = async () => {
				const data = await API.getDetails()
				if (data) {
					setIncorrect((i) => ({ ...i, error_ed: false }))
					if (!data.verified) {
						alert('Please verify email to continue')
						push('/auth')
						return
					}
					if (primary && data.ethereum_address !== primary) {
						const msg = `Wallet ${primary} is not connected with email ${data.email}. \n Please switch to ${data.ethereum_address}.`
						// alert(msg)
						setIncorrect({ msg, error_ed: true, title: 'Incorrect Wallet' })
					}
					if (network !== 56) {
						const msg = `Please switch to BSC Mainnet network.`
						// alert(msg)
						setIncorrect({ msg, error_ed: true, title: 'Incorrect Network' })
					}
				}
			}
			run()
		}
	}, [loggedIn, primary, network])

	const option = useAppSelector((state) => state.userDataReducer.option)
	const contracts_loaded = useAppSelector((state) => state.userDataReducer.contracts_loaded)
	const { push } = useHistory()
	return (
		<Fragment>
			{(!loggedIn || contracts_loaded || shared) && (
				<>
					{/* <LoadContracts></LoadContracts> */}
					<Nav></Nav>
					<Main
						Left={
							<Fragment>
								<div className=''>
									<div className='splitter header'>
									<a href='https://www.foxsports.com/scores/ufc' target='_blank' style={{textDecoration: "underline"}}>
										Sports Events & Schedule
									</a> <br/> <br/>
									Create New Bet
									</div>
									<div className='card'>
										<div className='inner'>
											<CreateNewBet />
											{/* <BridgeCard /> */}
										</div>
									</div>
									{/* {FeaturedBet()} */}
								</div>
							</Fragment>
						}
						Right={
							loggedIn && (
								<div>
									<RightCards />
									{/* <div className='RequestedBets'>
							<div className='splitter'>Requested Bets</div>
							<div className='card'>
								<div className='inner'>
									{new Array(5).fill(0).map(() => (
										<div className='row'>
											<div className='img'>
												<img src={face2} alt='' />
												<div className=''>
													<div className='name'>User Name</div>
													<div className='expires'>Joined 22 Feb 2020</div>
												</div>
											</div>
											<div className='num'>25</div>
										</div>
									))}
								</div>
							</div>
						</div> */}
								</div>
							)
						}
						Center={
							transactions ? (
								[
									<CardHeader
										title='Transactions'
										avatar={
											<IconButton onClick={() => push('/dashboard')} style={{ padding: '12px 0px' }}>
												<FontAwesomeIcon icon={faArrowLeft} />
											</IconButton>
										}
										titleTypographyProps={{ variant: 'h5' }}
									/>,
									<List>
										{descriptions.map((v, i) => (
											<ListItem className='transaction-card' key={i}>
												<ListItemText primary={v.description} />
											</ListItem>
										))}
									</List>,
								]
							) : shared ? (
								<Shared />
							) : (
								<div>
									{/* {bet && betData && (
									<div>
										Shared Bet:{betData.uid}
										{
											<WalletTransactions
												eth={`${betData.name}`}
												vnla={`${betData.amount}`}
												id={betData as any}
												status={betData.status}
												winner={betData.winner}
												approved={betData.approved}
												tag={betData?.bettor_b ? 'CLOSED' : 'OPEN'}
												type={0}
											/>
										}
										<div style={{ margin: '20px 0', border: '1px solid gray' }} className='spacer'></div>
									</div>
								)} */}
									{/* {option === 'listing' && <BetsListing></BetsListing>} */}
									<BetsListing />
									{option === 'transactions' && <RecentBetsHome></RecentBetsHome>}
								</div>
							)
						}
						tab={tab}
					></Main>
					<Footer value={tab} onChange={((_e: any, v: number) => setTab(v)) as any} />
				</>
			)}
			{/* <p style={{ width: '80%', margin: '0 auto', maxWidth: '600px', padding: '20px 0 0', lineHeight: '1.2', textAlign: 'center' }}>
				The Vanilla Bet platform has a dedicated service desk for all technical queries. For any issues experienced, please email our support
				team: <a href='mailto:support@vanillabet.io'>support@vanillabet.io</a> We aim to respond within 24 hours of your query.
			</p> */}

			<Dialog open={error_ed}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent style={{ lineHeight: 1.5 }}>{msg} </DialogContent>
				<DialogActions>
					{/* <Button onClick={()=>{}}>Cancel</Button> */}
					{/* <Button onClick={(run)}>Confirm</Button> */}
				</DialogActions>
			</Dialog>
		</Fragment>
	)
}
