import { faHandHoldingUsd, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CardHeader as MuiCardHeader, IconButton, useMediaQuery, useTheme, withStyles } from '@material-ui/core'
import { Fragment, useState } from 'react'
import { API, get_static_url } from '../../api/API'
import { face, logo } from '../../assets'
import Wallet from '../../pages/Home/Wallet'
import { useAppSelector } from '../../redux/hooks'
import { Link, useHistory } from 'react-router-dom'
import './Nav.scss'

const CardHeader = withStyles((_t) => ({
	content: {
		gap: _t.spacing(1),
		[_t.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'center',
		},
	},
}))(MuiCardHeader)

export function Nav() {
	const [open, setOpen] = useState(false)
	const [balance, setBalance] = useState('')
	const [profile, setProfile] = useState(localStorage.getItem('profile'))
	const { loggedIn } = useAppSelector((state) => state.userLoginReducer)
	const toggleOpen = () => setOpen((o) => !o)
	const history = useHistory()
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down('md'))

	const handleLogout = () => {
		history.push(`/auth`)
		localStorage.clear()
	}

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		API.update_avatar(e.target.files?.[0] as File)
			.then(setProfile)
			.catch(() => {})
	}

	return (
		<Fragment>
			<div className='Nav'>
				<div className='outer'>
					<div className='left'>
						<Link to='/'>
							<img src={logo} alt='' />
						</Link>
					</div>
					<div className='right'>
						{loggedIn ? (
							<>
								<CardHeader
									title={balance || 0}
									subheader='VNLA'
									{...(mobile && {
										titleTypographyProps: { variant: 'body1' },
										subheaderTypographyProps: { variant: 'body2' },
										style: { paddingRight: 0 },
									})}
								/>
								<Wallet {...{ open, toggleOpen, setBalance }} />
								{mobile ? (
									<>
										<IconButton onClick={toggleOpen}>
											<FontAwesomeIcon icon={faHandHoldingUsd} />
										</IconButton>
										<IconButton onClick={handleLogout}>
											<FontAwesomeIcon icon={faSignOutAlt} />
										</IconButton>
									</>
								) : (
									<>
										<Button onClick={toggleOpen}>Wallet </Button>
										<div style={{ padding: '0.4rem 0.7rem' }} onClick={handleLogout} className='common bttn uppercase'>
											Logout
										</div>
									</>
								)}
								<label htmlFor='upload-avatar'>
									<span className='circle'>
										<img
											src={get_static_url(profile || '') || face}
											alt='Profile'
											width={50}
											height={50}
											style={{ borderRadius: '50%', cursor: 'pointer' }}
										/>
									</span>
								</label>
								<input accept='image/*' className='upload' id='upload-avatar' type='file' onChange={handleUpload} name='file' />
							</>
						) : (
							<div
								style={{ padding: '0.4rem 0.7rem' }}
								onClick={() => {
									history.push('/auth')
									localStorage.clear()
								}}
								className='common bttn uppercase'
							>
								Login
							</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}
