import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Container from 'typedi'
import Web3 from 'web3'
import { API } from '../../api/API'
import { TreasuryAddress } from '../../config'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { UserDataActions } from '../../redux/slices/UserData'
import { BN } from '../../utilities/BN'
import { ContractsInstance } from '../../utilities/getContractInstance'

const contracts = Container.get(ContractsInstance)
export const Deposit: React.FC = () => {
	const [amount, setAmount] = useState('0')
	const dispatch = useAppDispatch()
	const [alerting, setAlerting] = useState(false)

	const primary = useAppSelector((state) => state.userDataReducer.primary)

	const handleSubmit = async () => {
		if (alerting) {
			alert('Transaction already midway? Do not click twice.')
			return
		}
		setAlerting(true)
		alert('Please stay on page until metamask transaction is completed!!!!')
		try {
			const token = contracts.get_token()
			const treasury = contracts.get_treasury()
			toast.info('Deposit in progress', {autoClose: false, toastId: 'deposit-in-progress'})
			const allowed = await token.methods.allowance(primary, TreasuryAddress).call()
			// console.log({ allowed, amount })
			if (new BN(allowed).isLessThan(Web3.utils.toWei(amount))) {
				await token.methods.approve(TreasuryAddress, Web3.utils.toWei(amount)).send({ from: primary })
			}
			const transaction = await treasury.methods.deposit(Web3.utils.toWei(amount)).send({ from: primary })
			// console.log({ transaction }, transaction.transactionHash)
			const transferredValue = (transaction as any)?.events?.Deposit?.returnValues.amount

			// console.log('Transactions data', transferredValue)

			await API.deposit(transaction.transactionHash, transferredValue)
			toast.dismiss('deposit-in-progress')
			setAmount('0')
			dispatch(UserDataActions.setUpdate())
		} catch (err) {
			toast.dismiss('deposit-in-progress')
			console.error(err)
		}
		setAlerting(false)
	}
	return (
		<div className='CreateNewBet'>
			<div className='form'>
				<div className='wrapper'>
					<label htmlFor='title' className='label'>
						<p>Deposit Amount</p>
					</label>
					<input
						placeholder={'Deposit Amount'}
						type='number'
						value={amount}
						onChange={(e) => {
							setAmount(e.target.value)
						}}
						name='amount'
						id='title'
					/>
				</div>
				{alerting && (
					<div style={{ textAlign: 'center', maxWidth: '80%', margin: '0 auto' }} className='red'>
						<p style={{ color: 'red' }}>Please stay on page until deposit completes!!! DO NOT REFRESH or EXIT</p>
					</div>
				)}
				<div style={{ width: '100%' }} className='wrapper'>
					<button className='submit bttn' onClick={() => handleSubmit()} disabled={alerting}>
						Deposit
					</button>
				</div>
			</div>
		</div>
	)
}

export const Withdraw: React.FC<{ max_amount: string }> = ({ max_amount } = { max_amount: '0' }) => {
	const [alerting, setAlerting] = useState(false)
	const [amount, setAmount] = useState('0')
	const dispatch = useAppDispatch()
	const primary = useAppSelector((state) => state.userDataReducer.primary)

	const handleSubmit = async () => {
		setAlerting(true)
		try {
			toast.info('Requesting withdrawal...', {toastId: "request-withdraw"})
			await API.add_withdrawable(Web3.utils.toWei(amount))
			toast.dismiss("request-withdraw")
			toast.success(`Withdrawal Requested of ${amount}VNLA successfully`)
		} catch (error) {
			toast.dismiss("request-withdraw")
			console.error(error)
		}
		setAlerting(false)
		dispatch(UserDataActions.setUpdate())
	}
	return (
		<div className='CreateNewBet'>
			<div className='form'>
				<div className='wrapper'>
					<label htmlFor='title' className='label'>
						<p>Withdraw Request</p>
					</label>
					<input
						placeholder={'Withdraw  Request'}
						type='number'
						value={amount}
						onChange={(e) => {
							setAmount(e.target.value)
						}}
						name='amount'
						id='title'
					/>
				</div>

				<div style={{ width: '100%' }} className='wrapper'>
					<button className='submit bttn' onClick={() => setAmount(max_amount)} disabled={alerting}>
						Max
					</button>
				</div>

				<div style={{ width: '100%' }} className='wrapper'>
					<button className='submit bttn' onClick={() => handleSubmit()} disabled={alerting}>
						Request Withdraw
						<br />
						(approval can take up to 24 h)
					</button>
				</div>
			</div>
		</div>
	)
}
