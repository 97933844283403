import { combineReducers, configureStore } from '@reduxjs/toolkit'

import userDataReducer from './slices/UserData'
import userLoginReducer from './slices/UserLogin'
import notificationReducer from './slices/Notification'

export const store = configureStore({
	reducer: combineReducers({
		userDataReducer,
		userLoginReducer,
		notificationReducer,
	})
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
