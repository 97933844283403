import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Tooltip } from '@material-ui/core'
import React from 'react'
import { Fragment, useState } from 'react'
import { toast } from 'react-toastify'
import { H2H } from '../../../../api/H2H'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { UserDataActions } from '../../../../redux/slices/UserData'
import Autocomplete from '@material-ui/lab/Autocomplete';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// import { API } from '../../../../API'
import './CreateNewBet.scss'
import Web3 from 'web3'
import { useHistory } from 'react-router'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { API } from '../../../../api/API'

interface BetFormProps {
	uid?: string
	category?: string
	sub_category?: string
	name?: string
	description?: string
	amount?: string
	start?: string
	end?: string
	result?: string
	isPvt?: boolean
	sharable_private?: boolean
	TeamA?: string
	TeamB?: string
	bettor_b?: string
	handleClose?: (e?: any) => void,
	editable?: boolean
}

const endDate = new Date(new Date().setTime(new Date().getTime() + 5 * 60 * 1000)).toLocaleString('sv').substring(0, 16).replace(' ', 'T')

export function CreateNewBet({
	uid = '',
	category = '',
	sub_category = '',
	name = '',
	description = '',
	amount = '0.5',
	start = new Date().toLocaleString('sv').substring(0, 16).replace(' ', 'T'),
	end = endDate,
	result = endDate,
	isPvt = false,
	TeamA = '',
	TeamB = '',
	bettor_b = '',
	handleClose = () => { },
	sharable_private = false,
	editable = false
}: BetFormProps) {
	const [isPrivate, setIsPrivate] = useState(isPvt)
	const [sharable, setSharable] = useState(sharable_private)
	const [categories, setCategories] = useState<{ [k: string]: string[] }>({})
	const [formData, setFormData] = useState({
		TeamA,
		TeamB,
		amount,
		category,
		sub_category,
		name,
		description,
		start,
		end,
		result,
		bettor_b,
	})
	const [loading, setLoading] = useState(false)
	const [users, setUsers] = useState<string[]>([])
	const [edit, setEdit] = useState(false);
	const history = useHistory()

	React.useEffect(() => {
		const run = async () => {
			try {
				setCategories((await axios.get(process.env.REACT_APP_CATEGORIES_URI || '/categories')).data)
			} catch (err) {
				console.error(err)
			}
		}
		if (!uid)
			run()
	}, [])

	React.useEffect(() => {
		const run = async () => {
			try {
				let userlist = await API.get_all_users()
				if (userlist)
					setUsers(userlist)
			} catch (err) {
				console.error(err)
			}
		}
		if (!uid)
			run()
	}, [])

	React.useEffect(() => {
		if (editable === true && !!uid)
			setEdit(true)
	}, [editable, uid])

	const { loggedIn } = useAppSelector((state) => state.userLoginReducer)
	const dispatch = useAppDispatch()
	const handleUpdate = (e: { target: { value: string; name: string } }, type?: 'text' | 'date' | 'number') => {
		let value: any = e.target.value
		if (type === 'date') {
			// value = new Date(e.target.value).getTime()
		}
		if (type === 'number') {
			value = parseFloat(value)
		}
		setFormData((formData) => ({ ...formData, [e.target.name]: value }))
	}

	const handleSubmit = async (e: React.SyntheticEvent<any>) => {
		e.preventDefault()
		setLoading(true)
		try {
			if (!loggedIn) {
				toast.info('Authentication required')
				return history.push('/auth')
			}
			if (!!uid) {
				const res = await H2H.editBet({
					uid,
					name: formData.name,
					description: formData.description,
					sharable_private: sharable,
					// ...formData,
					// uid,
					// amount: Web3.utils.toWei(formData.amount),
					// private: isPrivate,
					// start: new Date(formData.start).toISOString(),
					// end: new Date(formData.end).toISOString(),
					// result: new Date(formData.result).toISOString(),
				})
				// console.log(res)
				dispatch(UserDataActions.setUpdate())
				handleClose()
				setEdit(false)
				toast.info('Bet Edited')
			} else {
				// console.log({ formData })

				toast.info('Creating bet...', { toastId: "createBet"})
				await H2H[isPrivate ? 'createBetPrivate' : 'createBet']({
					...formData,
					amount: Web3.utils.toWei(formData.amount),
					start: new Date(formData.start).toISOString(),
					end: new Date(formData.end).toISOString(),
					result: new Date(formData.result).toISOString(),
					sharable_private: sharable,
				})
				toast.dismiss("createBet")
				toast.success('Bet created.')
				dispatch(UserDataActions.setUpdate())
				setIsPrivate(false)
				setFormData({
					...formData,
					category,
					sub_category,
					name,
					description,
					amount,
					start,
					end,
					result,
					bettor_b,
				})
			}
		} catch (error) {
			toast.dismiss("createBet")
			console.error(error)
		} finally {
			setLoading(false)
		}
	}
	return (
		<Fragment>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={1} alignItems='center'>
					<FormInfo title='Category of bet (Sports)' editable={edit}>
						<TextField
							select
							disabled={!!uid}
							fullWidth
							name='category'
							label='Category'
							size='small'
							margin='dense'
							value={formData.category}
							onChange={handleUpdate}
							variant='outlined'
						>
							<MenuItem value=''>None</MenuItem>
							{Object.keys(categories).map((key) => (
								<MenuItem key={key} value={key}>
									{key}
								</MenuItem>
							))}
							<MenuItem value='Other'>Other</MenuItem>
						</TextField>
					</FormInfo>
					<FormInfo title='Sub-Category of bet (Soccer)' editable={edit}>
						<TextField
							select
							fullWidth
							label='Sub-Category'
							name='sub_category'
							size='small'
							margin='dense'
							value={formData.sub_category}
							onChange={handleUpdate}
							disabled={!!uid || !formData.category}
							variant='outlined'
						>
							<MenuItem value=''>None</MenuItem>
							{categories[formData.category]?.map((key) => (
								<MenuItem key={key} value={key}>
									{key}
								</MenuItem>
							))}
						</TextField>
					</FormInfo>
					<FormInfo title='Title of bet' editable={false}>
						<TextField
							fullWidth
							name='name'
							label='Title of Bet'
							size='small'
							margin='dense'
							value={formData.name}
							onChange={handleUpdate}
							placeholder='Title of Bet'
							variant='outlined'
						/>
					</FormInfo>
					<FormInfo title='Event Link (optional)' editable={false}>
						<TextField
							fullWidth
							name='description'
							label='Description'
							size='small'
							margin='dense'
							value={formData.description}
							onChange={handleUpdate}
							placeholder='Event Link(optional)'
							variant='outlined'
						/>
					</FormInfo>
					<FormInfo title='Bet Amount' editable={edit}>
						<TextField
							fullWidth
							name='amount'
							disabled={!!uid}
							label='Bet Amount'
							size='small'
							margin='dense'
							value={formData.amount}
							onChange={handleUpdate}
							type='text'
							inputProps={{ min: '0.5', step: '0.01', inputMode: 'decimal' }}  
							variant='outlined'
						/>
					</FormInfo>
					<FormInfo title='End Date. Must be 5 minutes from now.' editable={edit}>
						<KeyboardDateTimePicker
							fullWidth
							name='end'
							disabled={!!uid}
							disablePast
							minDate={endDate}
							label='Betting ends'
							margin='dense'
							value={formData.end}
							onChange={(d: any, v) => {
								if(new Date(d).toString() === 'Invalid Date' || d === null) {
									handleUpdate({
										target: { name: 'end', value: 'Invalid Date' }
									})
								}else {
									handleUpdate({
										target: { name: 'end', value: d?.toISOString() || v as string }
									})
								}
							}}
							format="yyyy/MM/dd HH:mm"
							placeholder='Select date and time slot'
							inputVariant="outlined"
							size="small"
						/>
					</FormInfo>
					<FormInfo title='Result Date. Must be greater than or equal to end date.' editable={edit}>
						<KeyboardDateTimePicker
							fullWidth
							name='result'
							disabled={!!uid}
							disablePast
							label='Result date'
							margin='dense'
							minDate={endDate}
							value={formData.result}
							onChange={(d: any, v) => {
								if(new Date(d).toString() === 'Invalid Date') {
									handleUpdate({
										target: { name: 'result', value: 'Invalid Date' }
									})
								}else {
									handleUpdate({
										target: { name: 'result', value: d?.toISOString() || v as string }
									})}
								}
							}
							format="yyyy/MM/dd HH:mm"
							placeholder='Select date and time slot'
							inputVariant="outlined"
							size="small"
						/>
					</FormInfo>
					<FormControlLabel
						label='Direct Bet'
						disabled={!!uid}
						control={<Checkbox color='default' />}
						checked={isPrivate}
						labelPlacement='start'
						onChange={(_e, c) => setIsPrivate(c)}
						style={{ marginLeft: 0 }}
					/>
					{isPrivate && (
						<>
							<FormInfo title='Who are you betting against?' editable={edit}>
								<Autocomplete
									fullWidth
									freeSolo
									onChange={(_, value: any) => {
										handleUpdate({
											target: {
												name: 'bettor_b',
												value: value || '',
											},
										})
									}}
									disabled={!!uid}
									size='small'
									options={users}
									placeholder='Username of opposite bettor'
									renderInput={(params) =>
										<TextField
											onChange={(event: any) => {
												handleUpdate({
													target: {
														name: 'bettor_b',
														value: event.target.value || '',
													},
												})
											}}
											{...params}
											label='Username of opposite bettor'
											margin='dense'
											variant='outlined'
										/>
									}
								/>
								{/* <TextField
								fullWidth
								name='bettor_b'
								disabled={!!uid}
								type='text'
								label='Username of opposite bettor'
								size='small'
								margin='dense'
								value={formData.bettor_b}
								onChange={handleUpdate}
								placeholder='Username of opposite bettor'
								variant='outlined'
							/> */}
							</FormInfo>
							<FormControlLabel
								label='Private Bet'
								disabled={!!uid}
								control={<Checkbox color='default' />}
								labelPlacement='start'
								value={sharable}
								onChange={(_e, c) => setSharable(c)}
								style={{ marginLeft: 0 }}
							/>
						</>
					)}
				</Grid>
				<Button
					fullWidth
					variant='contained'
					color='primary'
					type='submit'
					className='CreateNewBet form wrapper submit bttn'
					disabled={loading}
				>
					{!!uid ? 'Edit Bet' : 'Create Bet'}
				</Button>
			</form>
		</Fragment>
	)
}

const FormInfo = ({ title, children, editable }: { title: string; children: React.ReactNode, editable: boolean }) => (
	<>
		<Grid item xs={11} style={editable ? { display: 'none' } : { display: 'block' }}>
			{children}
		</Grid>
		<Tooltip title={title} placement='top' enterTouchDelay={0} style={editable ? { display: 'none' } : { display: 'block' }}>
			<Grid item xs={1}>
				<FontAwesomeIcon icon={faInfoCircle as any} />
			</Grid>
		</Tooltip>
	</>
)
